@import "./src/assets/pages/exclusiveContent";
@import "./src/assets/pages/data";
@import "./src/assets/pages/ticket";
@import "variables";
@import "antdOveride";

.cursorPointer {
    cursor: pointer;
}

.cursorDefault {
    cursor: default !important;
}

.hide-component {
    display: none;
}

.pms-form-control {
    display: block;
    width: 100%;
    font-size: 1rem;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border-radius: .25rem;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;

    .ant-calendar-picker-input {
        height: 39px;
    }

    > div {
        padding: .3rem .75rem;
    }
}

.colorRed {
    color: red;
}

.isValidation {
    border-color: red !important;
}

.select-filter {
    width: 250px;
    margin-left: -110px;
}

.ant-pagination {
    margin-right: 25px !important;
}

.ibox {
    -webkit-box-shadow: -1px -1px 20px -7px rgba(0, 0, 0, 0.49);
    -moz-box-shadow: -1px -1px 20px -7px rgba(0, 0, 0, 0.49);
    box-shadow: -1px -1px 20px -7px rgba(0, 0, 0, 0.49);
}

.hdr-nav-bar {
    .navbar {
        .navbar-nav {
            li {
                a {
                    padding: 15px 5px;
                }
            }
        }
    }
}

.headerTitle {
    color: $color-primary-blur;
    margin-bottom: 5px;
}

.filterTag {
    .anticon-close {
        svg {
            margin-bottom: 4px;
            margin-left: 5px;
        }
    }
}

.listTable {
    .ant-table-content {
        .ant-tag {
            cursor: pointer;
        }
    }
}

.tableFilter {
    .ant-input {
        padding-left: 10px !important;
    }

    .ant-input-suffix {
        margin-left: 12px;
        margin-right: 7px;
    }

    .ant-btn {
        span[type="search"] {
            vertical-align: 0;
        }
    }
}

.anticon-calendar {
    vertical-align: 0.2em !important;
}

.pms-select {
    position: relative;
    background-color: #fff;
    border: 1px solid #d9d9d9;
    border-radius: 0 !important;
    -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    width: 100%;
    height: 32px;
    padding: 0 11px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    margin: 0;
    //padding: 0;
    color: rgba(0, 0, 0, 0.65);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    -webkit-font-feature-settings: 'tnum', "tnum";
    font-feature-settings: 'tnum', "tnum";
    //position: relative;
    display: inline-block;
}

.fix-header .page-wrapper {
    padding-top: 57px;
}

.side-mini-panel {
    top: 56px;
}

.side-mini-panel ul.mini-nav > li > a {
    padding: 6px 0 6px;
}

.menu-title {
    color: white;
    border-bottom: 1px solid #e6e6e6;
}

.togglediv {
    top: 23px;
}

body {
    background-color: #f4f6f9 !important;
    padding-top: 65px;
}

select:after {
    width: 0;
    height: 0;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-top: 6px solid #f00;
    position: absolute;
    top: 40%;
    right: 5px;
    content: "";
    z-index: 98;
}

.style-custom-multiple-select {
    height: 100% !important;

    .ant-select-selector {
        //overflow: scroll;
        height: 100% !important;
    }
}

.modal-content-pt-0 {
    .ant-modal-body {
        padding-top: 0;
    }
}

.form-component {
    .ant-form-item {
        margin-bottom: 0;
    }
}

.ydl-tag {
    .anticon-close {
        svg {
            margin-bottom: 50%;
        }
    }
}

.ydl-divider {
    &:after {
        top: 0
    }

    &:before {
        top: 0;
    }
}

.input-add-on {
    padding: 0 !important;
    border: 0 !important;

    .ant-input-group {
        height: 100% !important;

        input {
            height: 100%;
        }
    }
}

//
.ydl {
    &--topbar {
        background: -moz-linear-gradient(90deg, rgba(255, 255, 255, 1) 0%, rgba(219, 247, 255, 1) 35%, rgba(0, 164, 203, 1) 77%, rgba(18, 57, 99, 1) 100%);

        /* safari 5.1+,chrome 10+ */
        background: -webkit-linear-gradient(90deg, rgba(255, 255, 255, 1) 0%, rgba(219, 247, 255, 1) 35%, rgba(0, 164, 203, 1) 77%, rgba(18, 57, 99, 1) 100%);

        /* opera 11.10+ */
        background: -o-linear-gradient(90deg, rgba(255, 255, 255, 1) 0%, rgba(219, 247, 255, 1) 35%, rgba(0, 164, 203, 1) 77%, rgba(18, 57, 99, 1) 100%);

        /* ie 6-9 */
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#123963', endColorstr='#FFFFFF', GradientType=0);

        /* ie 10+ */
        background: -ms-linear-gradient(90deg, rgba(255, 255, 255, 1) 0%, rgba(219, 247, 255, 1) 35%, rgba(0, 164, 203, 1) 77%, rgba(18, 57, 99, 1) 100%);

        /* global 94%+ browsers support */
        background: linear-gradient(90deg, rgba(255, 255, 255, 1) 0%, rgba(219, 247, 255, 1) 35%, rgba(0, 164, 203, 1) 77%, rgba(18, 57, 99, 1) 100%);
        position: fixed;
        top: 0;
        width: 100%;

        .navbar {
            //height: 55px;
            padding: 8px 16px;
            line-height: 47px;

            &--brand {
                height: 55px;
                margin-top: -8px;
                width: auto;
                line-height: 47px;
                display: flex;
                justify-content: center;
                align-items: center;
            }

            &--button-collapse {
                font-size: 1.125rem;
                height: 55px;
                margin-top: -8px;
                width: 55px;
                padding-left: 28px;
                border: none;
                background-color: transparent;
            }

            &--nav {
                .nav {
                    &-item {
                        position: relative;
                    }
                }

                a {
                    line-height: 47px;
                    padding: 0px 1.125rem/2;

                    &::after {
                        content: none;
                    }
                }

                .dropdown-menu {
                    left: auto;
                    right: 0;
                    width: 200px;

                    ul {
                        padding-left: 0;

                        li {
                            list-style: none;
                            padding: 8px;
                            line-height: 1.75em;

                            &:first-child {
                                border-bottom: 1px solid #e6e6e6;
                            }
                        }
                    }

                    .drop-title {
                        padding: 0;
                        border-bottom: none;
                    }

                    .dropdown-user {
                        flex-direction: column;
                        border-radius: 4px;
                        border: 1px solid darken(#e6e6e6, 20);
                        min-width: 0;

                        .nav-item {
                            padding: 0;
                            border-bottom: 1px solid darken(#e6e6e6, 20);

                            a {
                                width: 100%;
                                font-weight: normal;
                                font-size: 14px;
                                line-height: 1.725rem;
                                color: lighten(#2f353a, 35%);
                                color: #222;

                                &:hover {
                                    background-color: lighten(#2f353a, 75%);
                                }

                                &.user-name {
                                    text-align: center;
                                    font-weight: bold;
                                    font-size: 0.95em;
                                    color: lighten(#2f353a, 25%);
                                    background-color: lighten(#2f353a, 65%);
                                }

                                i {
                                    color: #c8ced3;
                                    margin-right: 8px;
                                }
                            }
                        }

                        h4 {
                            margin: 0;
                        }
                    }
                }
            }
        }
    }

    &--main-panel {
        background-color: white;
        -webkit-box-shadow: 15px 6px 38px -11px rgba(0, 0, 0, 0.2);
        box-shadow: 15px 6px 38px -11px rgba(0, 0, 0, 0.2);
        width: 200px;
        position: fixed;
        top: 55px;
        left: 0;
        height: calc(100vh - 55px);
        overflow: scroll;
        padding-top: 16px;
        padding-bottom: 16px;

        .nav {
            color: #fff;
            flex-direction: column;

            h3.menu-title {
                font-size: 11.5px;
                text-transform: uppercase;
                padding: 0 16px;
                border: none;
                font-weight: 600;
            }

            a {
                color: darken(#fff, 25%);
            }

            &-item {
                font-size: 14px;

                &.active {
                    a {
                        color: #fff;
                    }

                    border-radius: 0;
                    background-color: $color-primary-blur;
                    border: none;
                }
            }

            &.sub-menu {
                .nav-item {
                    padding: 0;
                    width: 100%;

                    &:hover {
                        background-color: #20a8d8;

                        a {
                            color: #fff;
                        }
                    }
                }
            }
        }
    }
}

.page-wrapper {
    transition-property: margin-left;
    transition-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
    transition-duration: 0.3s;
}

.page-wrapper.expanded {
    margin-left: 0 !important;
}

.fs {
    @for $f from 1 through 100 {
        &--#{$f}px {
            font-size: #{$f}px !important;
        }
    }

    @for $f from 1 through 100 {
        &--#{$f}rem {
            font-size: #{$f}rem !important;
        }
    }
}

.color {
    &--primary {
        color: $color-primary;
    }

    &--primary-blur {
        color: $color-primary-blur;
    }

    &--description {
        color: rgba(0, 0, 0, 0.45)
    }

    &-red {
        &-2 {
            color: #ff4d4f
        }
    }

    &--black {
        color: black !important;
    }

    &--white {
        color: white !important;
    }
}

.bg {
    &--primary-blur {
        background-color: $color-primary-blur;
    }
}

.border-color {
    &--primary-blur {
        border-color: $color-primary-blur;
    }
}

.btn-primary {
    background-color: $color-primary-blur;
    border-color: $color-primary-blur;

    &:hover {
        background-color: rgba($color-primary-blur, 0.7);
        border-color: rgba($color-primary-blur, 0.7);
    }
}

.vertical {
    &--middle {
        vertical-align: middle !important;
    }
}

.report-voucher-detail {
    &__history-log {
        overflow-y: scroll;
        height: 500px;
    }
}

//.width--90 {
//    width: 90%;
//}

.width {
    @for $w from 1 through 500 {
        &-#{$w}px {
            width: #{$w}px !important;
        }
    }

    @for $w from 1 through 500 {
        &-#{$w}rem {
            width: #{$w}rem !important;
        }
    }

    @for $w from 1 through 100 {
        &-#{$w} {
            width: #{$w}+"%" !important;
        }
    }
}

.max-width {
    @for $w from 1 through 2000 {
        &--#{$w}px {
            max-width: #{$w}px !important;
        }
    }

    @for $w from 1 through 2000 {
        &--#{$w}rem {
            width: #{$w}rem !important;
        }
    }

    @for $w from 1 through 100 {
        &--#{$w} {
            width: #{$w}% !important;
        }
    }
    //&-90{
    //    width: 90% !important;
    //}
}

.max-height {
    @for $w from 1 through 2000 {
        &--#{$w}px {
            max-height: #{$w}px !important;
        }
    }
}

.min-height {
    @for $w from 1 through 2000 {
        &--#{$w}px {
            min-height: #{$w}px !important;
        }
    }
}

.hide-symbol-required {
    .ant-form-item-required {
        &:before {
            content: "" !important;
        }
    }
}

.sub-menu {
    a {
        text-decoration: none;
    }
}

.data-upload {
    .ant-upload-list-item-name {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        max-width: 100px;
    }
}

.chart-component svg > g > g:last-child {
    pointer-events: none
}

.ant-tag {
    svg {
        margin-top: -5px;
    }
}

.hover {
    &--primary {
        &:hover {
            color: $color-primary-blur !important;
        }
    }
}

