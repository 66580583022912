@import url("https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700");
@import url("https://fonts.googleapis.com/css?family=Roboto:400,300,500,700");

h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: 100;
}

.h1,
.h2,
.h3,
h1,
h2,
h3 {
    margin-top: 20px;
    margin-bottom: 10px;
}

h1 {
    font-size: 30px;
}

h2 {
    font-size: 24px;
}

h3 {
    font-size: 16px;
}

h4 {
    font-size: 14px;
}

h5 {
    font-size: 12px;
}

h6 {
    font-size: 10px;
}

h3,
h4,
h5 {
    margin-top: 5px;
    font-weight: 600;
}

.nav > li > a {
    color: #a7b1c2;
    font-weight: 600;
    padding: 14px 20px 14px 25px;
    display: block;
}

.nav.metismenu > li {
    display: block;
    width: 100%;
    position: relative;
}

.nav.metismenu .dropdown-menu > li > a {
    padding: 3px 20px;
    display: block;
}

.nav.navbar-right > li > a {
    color: #999c9e;
}

.nav > li.active > a {
    color: #818489;
}

.navbar-default .nav > li > a:hover,
.navbar-default .nav > li > a:focus {
    background-color: #F6F7FA;
    border-radius: 0 30px 30px 0;
    /*color: white;*/
}

.nav .open > a,
.nav .open > a:hover,
.nav .open > a:focus {
    background: #fff;
}

.nav.navbar-top-links > li > a:hover,
.nav.navbar-top-links > li > a:focus {
    background-color: transparent;
}

.nav > li > a i {
    margin-right: 6px;
}

.navbar {
    border: 0;
}

.navbar-default {
    background-color: transparent;
    border-color: #ffffff;
}

.navbar-top-links li {
    display: inline-block;
    align-self: center;
}

.navbar-top-links li:last-child {
    margin-right: 30px;
}

.body-small .navbar-top-links li:last-child {
    margin-right: 0;
}

.navbar-top-links li a {
    padding: 20px 10px;
    min-height: 50px;
}

.dropdown-menu {
    border: medium none;
    border-radius: 3px;
    box-shadow: 0 0 3px rgba(86, 96, 117, 0.7);
    display: none;
    float: left;
    font-size: 12px;
    left: 0;
    list-style: none outside none;
    padding: 0;
    position: absolute;
    text-shadow: none;
    top: 100%;
    z-index: 1000;
}

.dropdown-menu > li > a {
    border-radius: 3px;
    color: inherit;
    line-height: 25px;
    margin: 4px;
    text-align: left;
    font-weight: normal;
    display: block;
    padding: 3px 20px;
}

.dropdown-menu > li > a:focus,
.dropdown-menu > li > a:hover {
    color: #262626;
    text-decoration: none;
    background-color: #f5f5f5;
}

.dropdown-menu > .active > a,
.dropdown-menu > .active > a:focus,
.dropdown-menu > .active > a:hover {
    color: #fff;
    text-decoration: none;
    background-color: #1ab394;
    outline: 0;
}

.dropdown-menu > li > a.font-bold {
    font-weight: 600;
}

.navbar-top-links .dropdown-menu li {
    display: block;
}

.navbar-top-links .dropdown-menu li:last-child {
    margin-right: 0;
}

.navbar-top-links .dropdown-menu li a {
    padding: 3px 20px;
    min-height: 0;
}

.navbar-top-links .dropdown-menu li a div {
    white-space: normal;
}

.navbar-top-links .dropdown-messages,
.navbar-top-links .dropdown-tasks,
.navbar-top-links .dropdown-alerts {
    width: 310px;
    min-width: 0;
}

.navbar-top-links .dropdown-messages {
    margin-left: 5px;
}

.navbar-top-links .dropdown-tasks {
    margin-left: -59px;
}

.navbar-top-links .dropdown-alerts {
    margin-left: -123px;
}

.navbar-top-links .dropdown-user {
    right: 0;
    left: auto;
}

.dropdown-messages,
.dropdown-alerts {
    padding: 10px 10px 10px 10px;
}

.dropdown-messages li a,
.dropdown-alerts li a {
    font-size: 12px;
}

.dropdown-messages li em,
.dropdown-alerts li em {
    font-size: 10px;
}

.nav.navbar-top-links .dropdown-alerts a {
    font-size: 12px;
}

.nav-header {
    padding: 33px 25px;
    background-color: #ffffff;
    /*background-image: url("patterns/header-profile.png");*/
}

.caret {
    display: inline-block;
    width: 0;
    height: 0;
    margin-left: 2px;
    vertical-align: middle;
    border-top: 4px dashed;
    border-right: 4px solid transparent;
    border-left: 4px solid transparent;
}

.profile-element .dropdown-toggle::after {
    display: none;
}

.pace-done .nav-header {
    transition: all 0.4s;
}

ul.nav-second-level {
    background: #F6F7FA;
}

.nav > li.active {
    border-left: 4px solid #E9A523;
    border-radius: 0 30px 30px 0;
    background: #F6F7FA;
}

.nav.nav-second-level > li.active {
    border: none;
}

.nav.nav-second-level.collapse[style] {
    height: auto !important;
}

.nav-header a {
    color: #DFE4ED;
}

.nav-header .text-muted {
    color: #8095a8 !important;
}

.minimalize-styl-2 {
    padding: 4px 12px;
    margin: 14px 5px 5px 20px;
    font-size: 14px;
    float: left;
}

.navbar-form-custom {
    float: left;
    height: 50px;
    padding: 0;
    width: 200px;
    display: block;
}

.navbar-form-custom .form-group {
    margin-bottom: 0;
}

.nav.navbar-top-links a {
    font-size: 14px;
}

.navbar-form-custom .form-control {
    background: none repeat scroll 0 0 rgba(0, 0, 0, 0);
    border: medium none;
    font-size: 14px;
    height: 60px;
    margin: 0;
    z-index: 2000;
}

.nav.navbar-top-links .dropdown-toggle::after {
    display: none;
}

@media (min-width: 768px) {
    .navbar-right {
        margin-right: -15px;
    }
}

.navbar.navbar-static-top {
    padding: 0;
    width: 100%;
    align-items: inherit;
}

.navbar-static-top .dropdown-menu {
    right: 0;
    left: auto;
}

.count-info .label {
    line-height: 12px;
    padding: 2px 5px;
    position: absolute;
    right: 6px;
    top: 12px;
}

.arrow {
    float: right;
}

.fa.arrow:before {
    content: "\f104";
}

.active > a > .fa.arrow:before {
    content: "\f107";
}

.nav-second-level li,
.nav-third-level li {
    border-bottom: none !important;
}

.nav-second-level li a {
    padding: 7px 10px 7px 10px;
    padding-left: 52px;
}

.fixed-sidebar.mini-navbar .nav-second-level.collapsing li a,
.nav-second-level.collapsing li a {
    min-width: 220px;
}

.body-small .nav-second-level.collapsing li a,
.mini-navbar .nav-second-level.collapsing li a {
    min-width: 140px;
}

.nav-third-level li a,
.fixed-sidebar.mini-navbar .nav-second-level li .nav-third-level li a {
    padding-left: 62px;
}

.nav-second-level li:last-child {
    padding-bottom: 10px;
}

body:not(.fixed-sidebar):not(.canvas-menu).mini-navbar .nav li:hover > .nav-second-level,
.mini-navbar .nav li:focus > .nav-second-level {
    display: block;
    border-radius: 0 2px 2px 0;
    min-width: 160px;
    height: auto;
}

body.mini-navbar .navbar-default .nav > li > .nav-second-level li a {
    font-size: 12px;
    border-radius: 3px;
}

.fixed-nav .slimScrollDiv #side-menu {
    padding-bottom: 60px;
}

.mini-navbar .nav-second-level li a {
    padding: 10px 10px 10px 15px;
}

.mini-navbar .nav .nav-second-level {
    position: absolute;
    left: 70px;
    top: 0;
    background-color: #ffffff;

    padding: 10px 10px 10px 10px;
    font-size: 12px;
}

.canvas-menu.mini-navbar .nav-second-level {
    background: #F6F7FA;
}

.mini-navbar li.active .nav-second-level {
    left: 65px;
}

.navbar-default .special_link a {
    background: #1ab394;
    color: white;
}

.navbar-default .special_link a:hover {
    background: #17987e !important;
    color: white;
}

.navbar-default .special_link a span.label {
    background: #fff;
    color: #1ab394;
}

.navbar-default .landing_link a {
    background: #1cc09f;
    color: white;
}

.navbar-default .landing_link a:hover {
    background: #1ab394 !important;
    color: white;
}

.navbar-default .landing_link a span.label {
    background: #fff;
    color: #1cc09f;
}

.logo-element {
    text-align: center;
    font-size: 18px;
    font-weight: 600;
    color: white;
    display: none;
    padding: 18px 0;
}

.pace-done .navbar-static-side,
.pace-done .nav-header,
.pace-done li.active,
.pace-done #page-wrapper,
.pace-done .footer {
    -webkit-transition: all 0.4s;
    -moz-transition: all 0.4s;
    -o-transition: all 0.4s;
    transition: all 0.4s;
}

.navbar-fixed-top {
    background: #fff;
    transition-duration: 0.4s;
    border-bottom: 1px solid #e7eaec !important;
    z-index: 2030;
    position: fixed;
    right: 0;
    left: 0;
    padding: 0;
    top: 0
}

.navbar-fixed-top .navbar-form-custom .form-control {
    height: 50px
}

.navbar-fixed-top,
.navbar-static-top {
    background: #ffffff;
}

.fixed-nav #wrapper {
    margin-top: 0;
}

.nav-tabs > li.active > a,
.nav-tabs > li.active > a:hover,
.nav-tabs > li.active > a:focus {
    -moz-border-bottom-colors: none;
    -moz-border-left-colors: none;
    -moz-border-right-colors: none;
    -moz-border-top-colors: none;
    background: none;
    border-color: #dddddd #dddddd rgba(0, 0, 0, 0);
    border-bottom: #f3f3f4;
    border-image: none;
    border-style: solid;
    border-width: 1px;
    color: #555555;
    cursor: default;
}

.nav.nav-tabs li {
    background: none;
    border: none;
}

body.fixed-nav #wrapper .navbar-static-side,
body.fixed-nav #wrapper #page-wrapper {
    margin-top: 60px;
}

body.top-navigation.fixed-nav #wrapper #page-wrapper {
    margin-top: 0;
}

body.fixed-nav.fixed-nav-basic .navbar-fixed-top {
    left: 220px;
}

body.fixed-nav.fixed-nav-basic.mini-navbar .navbar-fixed-top {
    left: 70px;
}

body.fixed-nav.fixed-nav-basic.fixed-sidebar.mini-navbar .navbar-fixed-top {
    left: 0;
}

body.fixed-nav.fixed-nav-basic #wrapper .navbar-static-side {
    margin-top: 0;
}

body.fixed-nav.fixed-nav-basic.body-small .navbar-fixed-top {
    left: 0;
}

body.fixed-nav.fixed-nav-basic.fixed-sidebar.mini-navbar.body-small .navbar-fixed-top {
    left: 220px;
}

.fixed-nav .minimalize-styl-2 {
    margin: 10px 5px 5px 15px;
}

.body-small .navbar-fixed-top {
    margin-left: 0;
}

body.mini-navbar .navbar-static-side {
    width: 70px;
}

body.mini-navbar .profile-element,
body.mini-navbar .nav-label,
body.mini-navbar .navbar-default .nav li a span {
    display: none;
}

body.canvas-menu .profile-element {
    display: block;
}

body:not(.fixed-sidebar):not(.canvas-menu).mini-navbar .nav-second-level {
    display: none;
}

body.mini-navbar .navbar-default .nav > li > a {
    font-size: 16px;
}

body.mini-navbar .logo-element {
    display: block;
}

body.canvas-menu .logo-element {
    display: none;
}

body.mini-navbar .nav-header {
    padding: 0;
    background-color: #1ab394;
}

body.canvas-menu .nav-header {
    padding: 33px 25px;
}

body.mini-navbar #page-wrapper {
    margin: 0 0 0 70px;
}

body.fixed-sidebar.mini-navbar .footer,
body.canvas-menu.mini-navbar .footer {
    margin: 0 0 0 0 !important;
}

body.canvas-menu.mini-navbar #page-wrapper,
body.canvas-menu.mini-navbar .footer {
    margin: 0 0 0 0;
}

body.fixed-sidebar .navbar-static-side,
body.canvas-menu .navbar-static-side {
    position: fixed;
    width: 220px;
    z-index: 2001;
    height: 100%;
}

body.fixed-sidebar.mini-navbar .navbar-static-side {
    width: 0;
}

body.fixed-sidebar.mini-navbar #page-wrapper {
    margin: 0 0 0 0;
}

body.body-small.fixed-sidebar.mini-navbar #page-wrapper {
    margin: 0 0 0 220px;
}

body.body-small.fixed-sidebar.mini-navbar .navbar-static-side {
    width: 220px;
}

.fixed-sidebar.mini-navbar .nav li:focus > .nav-second-level,
.canvas-menu.mini-navbar .nav li:focus > .nav-second-level {
    display: block;
    height: auto;
}

body.fixed-sidebar.mini-navbar .navbar-default .nav > li > .nav-second-level li a {
    font-size: 12px;
    border-radius: 3px;
}

body.canvas-menu.mini-navbar .navbar-default .nav > li > .nav-second-level li a {
    font-size: 13px;
    border-radius: 3px;
}

.fixed-sidebar.mini-navbar .nav-second-level li a,
.canvas-menu.mini-navbar .nav-second-level li a {
    padding: 10px 10px 10px 15px;
}

.fixed-sidebar.mini-navbar .nav-second-level,
.canvas-menu.mini-navbar .nav-second-level {
    position: relative;
    padding: 0;
    font-size: 13px;
}

.fixed-sidebar.mini-navbar li.active .nav-second-level,
.canvas-menu.mini-navbar li.active .nav-second-level {
    left: 0;
}

body.fixed-sidebar.mini-navbar .navbar-default .nav > li > a,
body.canvas-menu.mini-navbar .navbar-default .nav > li > a {
    font-size: 13px;
}

body.fixed-sidebar.mini-navbar .nav-label,
body.fixed-sidebar.mini-navbar .navbar-default .nav li a span,
body.canvas-menu.mini-navbar .nav-label,
body.canvas-menu.mini-navbar .navbar-default .nav li a span {
    display: inline;
}

body.canvas-menu.mini-navbar .navbar-default .nav li .profile-element a span {
    display: block;
}

.canvas-menu.mini-navbar .nav-second-level li a,
.fixed-sidebar.mini-navbar .nav-second-level li a {
    padding: 7px 10px 7px 52px;
}

.fixed-sidebar.mini-navbar .nav-second-level,
.canvas-menu.mini-navbar .nav-second-level {
    left: 0;
}

body.canvas-menu nav.navbar-static-side {
    z-index: 2001;
    background: #ffffff;
    height: 100%;
    position: fixed;
    display: none;
}

body.canvas-menu.mini-navbar nav.navbar-static-side {
    display: block;
    width: 220px;
}

.top-navigation #page-wrapper {
    margin-left: 0;
}

.top-navigation .navbar-nav .dropdown-menu > .active > a {
    background: white;
    color: #1ab394;
    font-weight: bold;
}

.white-bg .navbar-fixed-top,
.white-bg .navbar-static-top {
    background: #fff;
}

.top-navigation .navbar {
    margin-bottom: 0;
}

.top-navigation .nav > li > a {
    padding: 15px 20px;
    color: #676a6c;
}

.top-navigation .nav > li a:hover,
.top-navigation .nav > li a:focus {
    background: #fff;
    color: #1ab394;
}

.top-navigation .navbar .nav > li.active {
    background: #fff;
    border: none;
}

.top-navigation .nav > li.active > a {
    color: #1ab394;
}

.top-navigation .navbar-right {
    margin-right: 10px;
}

.top-navigation .navbar-nav .dropdown-menu {
    box-shadow: none;
    border: 1px solid #e7eaec;
}

.top-navigation .dropdown-menu > li > a {
    margin: 0;
    padding: 7px 20px;
}

.navbar .dropdown-menu {
    margin-top: 0;
}

.top-navigation .navbar-brand {
    background: #1ab394;
    color: #fff;
    padding: 15px 25px;
    font-size: 18px;
    line-height: 20px;
}

.top-navigation .navbar-top-links li:last-child {
    margin-right: 0;
}

.top-navigation.mini-navbar #page-wrapper,
.top-navigation.body-small.fixed-sidebar.mini-navbar #page-wrapper,
.mini-navbar .top-navigation #page-wrapper,
.body-small.fixed-sidebar.mini-navbar .top-navigation #page-wrapper,
.canvas-menu #page-wrapper {
    margin: 0;
}

.top-navigation.fixed-nav #wrapper,
.fixed-nav #wrapper.top-navigation {
    margin-top: 50px;
}

.top-navigation .footer.fixed {
    margin-left: 0 !important;
}

.top-navigation .wrapper.wrapper-content {
    padding: 40px;
}

.top-navigation.body-small .wrapper.wrapper-content,
.body-small .top-navigation .wrapper.wrapper-content {
    padding: 40px 0 40px 0;
}

.navbar-toggler {
    background-color: #1ab394;
    color: #fff;
    padding: 6px 12px;
    font-size: 14px;
    margin: 8px;
}

.top-navigation .navbar-nav .open .dropdown-menu > li > a,
.top-navigation .navbar-nav .open .dropdown-menu .dropdown-header {
    padding: 10px 15px 10px 20px;
}

@media (max-width: 768px) {
    .top-navigation .navbar-header {
        display: block;
        float: none;
    }
}

.menu-visible-lg,
.menu-visible-md {
    display: none !important;
}

@media (min-width: 1200px) {
    .menu-visible-lg {
        display: block !important;
    }
}

@media (min-width: 992px) {
    .menu-visible-md {
        display: block !important;
    }
}

@media (max-width: 767px) {
    .menu-visible-md {
        display: block !important;
    }

    .menu-visible-lg {
        display: block !important;
    }
}

button:focus {
    outline: 0 !important;
}

.btn {
    border-radius: 3px;
    font-size: inherit;
}

.btn:focus {
    box-shadow: none;
}

.btn-xs {
    font-size: 0.7rem;
    padding: 0.2rem 0.4rem;
}

.btn-group-sm > .btn,
.btn-sm {
    font-size: .8rem;
}

.float-e-margins .btn {
    margin-bottom: 5px;
}

.btn-w-m {
    min-width: 120px;
}

.btn-primary.btn-outline {
    color: #1ab394;
}

.btn-success.btn-outline {
    color: #1c84c6;
}

.btn-info.btn-outline {
    color: #23c6c8;
}

.btn-warning.btn-outline {
    color: #f8ac59;
}

.btn-danger.btn-outline {
    color: #ed5565;
}

.btn-primary.btn-outline:hover,
.btn-success.btn-outline:hover,
.btn-info.btn-outline:hover,
.btn-warning.btn-outline:hover,
.btn-danger.btn-outline:hover {
    color: #fff;
}

.btn.active,
.btn:active {
    background-image: none;
    outline: 0;
    -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}

.btn-primary {
    color: #fff;
    background-color: #1ab394;
    border-color: #1ab394;
}

.btn-primary:hover,
.btn-primary:focus,
.btn-primary.focus {
    background-color: #18a689;
    border-color: #18a689;
    color: #FFFFFF;
}

.btn-primary.disabled,
.btn-primary:disabled {
    color: #fff;
    background-color: #18a689;
    border-color: #18a689;
}

.btn-primary:not(:disabled):not(.disabled):active,
.btn-primary:not(:disabled):not(.disabled).active,
.show > .btn-primary.dropdown-toggle {
    color: #fff;
    background-color: #18a689;
    border-color: #18a689;
}

.btn-primary:not(:disabled):not(.disabled):active:focus,
.btn-primary:not(:disabled):not(.disabled).active:focus,
.show > .btn-primary.dropdown-toggle:focus {
    -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}

.btn-success {
    color: #fff;
    background-color: #1c84c6;
    border-color: #1c84c6;
}

.btn-success:hover,
.btn-success:focus,
.btn-success.focus {
    color: #fff;
    background-color: #1a7bb9;
    border-color: #1a7bb9;
}

.btn-success.disabled,
.btn-success:disabled {
    color: #fff;
    background-color: #1a7bb9;
    border-color: #1a7bb9;
}

.btn-success:not(:disabled):not(.disabled):active,
.btn-success:not(:disabled):not(.disabled).active,
.show > .btn-success.dropdown-toggle {
    color: #fff;
    background-color: #1a7bb9;
    border-color: #1a7bb9;
}

.btn-success:not(:disabled):not(.disabled):active:focus,
.btn-success:not(:disabled):not(.disabled).active:focus,
.show > .btn-success.dropdown-toggle:focus {
    -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}

.btn-info {
    color: #fff;
    background-color: #23c6c8;
    border-color: #23c6c8;
}

.btn-info:hover,
.btn-info:focus,
.btn-info.focus {
    color: #fff;
    background-color: #21b9bb;
    border-color: #21b9bb;
}

.btn-info.disabled,
.btn-info:disabled {
    color: #fff;
    background-color: #21b9bb;
    border-color: #21b9bb;
}

.btn-info:not(:disabled):not(.disabled):active,
.btn-info:not(:disabled):not(.disabled).active,
.show > .btn-info.dropdown-toggle {
    color: #fff;
    background-color: #21b9bb;
    border-color: #21b9bb;
}

.btn-info:not(:disabled):not(.disabled):active:focus,
.btn-info:not(:disabled):not(.disabled).active:focus,
.show > .btn-info.dropdown-toggle:focus {
    -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}

.btn-default {
    color: inherit;
    background: white;
    border: 1px solid #e7eaec;
}

.btn-default:hover,
.btn-default:focus,
.btn-default:active,
.btn-default.active,
.open .dropdown-toggle.btn-default,
.btn-default:active:focus,
.btn-default:active:hover,
.btn-default.active:hover,
.btn-default.active:focus {
    color: inherit;
    border: 1px solid #d2d2d2;
}

.btn-default:active,
.btn-default.active,
.open .dropdown-toggle.btn-default {
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15) inset;
}

.btn-default.disabled,
.btn-default.disabled:hover,
.btn-default.disabled:focus,
.btn-default.disabled:active,
.btn-default.disabled.active,
.btn-default[disabled],
.btn-default[disabled]:hover,
.btn-default[disabled]:focus,
.btn-default[disabled]:active,
.btn-default.active[disabled],
fieldset[disabled] .btn-default,
fieldset[disabled] .btn-default:hover,
fieldset[disabled] .btn-default:focus,
fieldset[disabled] .btn-default:active,
fieldset[disabled] .btn-default.active {
    color: #cacaca;
}

.btn-warning {
    color: #ffffff;
    background-color: #f8ac59;
    border-color: #f8ac59;
}

.btn-warning:hover,
.btn-warning:focus,
.btn-warning.focus {
    color: #ffffff;
    background-color: #f7a54a;
    border-color: #f7a54a;
}

.btn-warning.disabled,
.btn-warning:disabled {
    color: #ffffff;
    background-color: #f7a54a;
    border-color: #f7a54a;
}

.btn-warning:not(:disabled):not(.disabled):active,
.btn-warning:not(:disabled):not(.disabled).active,
.show > .btn-warning.dropdown-toggle {
    color: #ffffff;
    background-color: #f7a54a;
    border-color: #f7a54a;
}

.btn-warning:not(:disabled):not(.disabled):active:focus,
.btn-warning:not(:disabled):not(.disabled).active:focus,
.show > .btn-warning.dropdown-toggle:focus {
    -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}

.btn-danger {
    color: #fff;
    background-color: #ed5565;
    border-color: #ed5565;
}

.btn-danger:hover,
.btn-danger:focus,
.btn-danger.focus {
    color: #fff;
    background-color: #ec4758;
    border-color: #ec4758;
}

.btn-danger.disabled,
.btn-danger:disabled {
    color: #fff;
    background-color: #ec4758;
    border-color: #ec4758;
}

.btn-danger:not(:disabled):not(.disabled):active,
.btn-danger:not(:disabled):not(.disabled).active,
.show > .btn-danger.dropdown-toggle {
    color: #fff;
    background-color: #ec4758;
    border-color: #ec4758;
}

.btn-danger:not(:disabled):not(.disabled):active:focus,
.btn-danger:not(:disabled):not(.disabled).active:focus,
.show > .btn-danger.dropdown-toggle:focus {
    -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}

.btn-link {
    color: inherit;
}

.btn-link:hover,
.btn-link:focus,
.btn-link:active,
.btn-link.active,
.open .dropdown-toggle.btn-link {
    color: #1ab394;
    text-decoration: none;
}

.btn-link:active,
.btn-link.active,
.open .dropdown-toggle.btn-link {
    background-image: none;
    box-shadow: none;
}

.btn-link.disabled,
.btn-link.disabled:hover,
.btn-link.disabled:focus,
.btn-link.disabled:active,
.btn-link.disabled.active,
.btn-link[disabled],
.btn-link[disabled]:hover,
.btn-link[disabled]:focus,
.btn-link[disabled]:active,
.btn-link.active[disabled],
fieldset[disabled] .btn-link,
fieldset[disabled] .btn-link:hover,
fieldset[disabled] .btn-link:focus,
fieldset[disabled] .btn-link:active,
fieldset[disabled] .btn-link.active {
    color: #cacaca;
}

.btn-white {
    color: inherit;
    background: white;
    border: 1px solid #e7eaec;
}

.btn-white:hover,
.btn-white:focus,
.btn-white:active,
.btn-white.active,
.open .dropdown-toggle.btn-white,
.btn-white:active:focus,
.btn-white:active:hover,
.btn-white.active:hover,
.btn-white.active:focus {
    color: inherit;
    border: 1px solid #d2d2d2;
}

.btn-white:active,
.btn-white.active {
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15) inset;
}

.btn-white:active,
.btn-white.active,
.open .dropdown-toggle.btn-white {
    background-image: none;
}

.btn-white.disabled,
.btn-white.disabled:hover,
.btn-white.disabled:focus,
.btn-white.disabled:active,
.btn-white.disabled.active,
.btn-white[disabled],
.btn-white[disabled]:hover,
.btn-white[disabled]:focus,
.btn-white[disabled]:active,
.btn-white.active[disabled],
fieldset[disabled] .btn-white,
fieldset[disabled] .btn-white:hover,
fieldset[disabled] .btn-white:focus,
fieldset[disabled] .btn-white:active,
fieldset[disabled] .btn-white.active {
    color: #cacaca;
}

.form-control,
.form-control:focus,
.has-error .form-control:focus,
.has-success .form-control:focus,
.has-warning .form-control:focus,
.navbar-collapse,
.navbar-form,
.navbar-form-custom .form-control:focus,
.navbar-form-custom .form-control:hover,
.open .btn.dropdown-toggle,
.panel,
.popover,
.progress,
.progress-bar {
    box-shadow: none;
}

.btn-outline {
    color: inherit;
    background-color: transparent;
    transition: all .5s;
}

.btn-rounded {
    border-radius: 50px;
}

.btn-large-dim {
    width: 90px;
    height: 90px;
    font-size: 42px;
}

button.dim {
    display: inline-block;
    text-decoration: none;
    text-transform: uppercase;
    text-align: center;
    padding-top: 6px;
    margin-right: 10px;
    position: relative;
    cursor: pointer;
    border-radius: 5px;
    font-weight: 600;
    margin-bottom: 20px !important;
}

button.dim:active {
    top: 3px;
}

button.btn-primary.dim {
    box-shadow: inset 0 0 0 #16987e, 0 5px 0 0 #16987e, 0 10px 5px #999999 !important;
}

button.btn-primary.dim:active {
    box-shadow: inset 0 0 0 #16987e, 0 2px 0 0 #16987e, 0 5px 3px #999999 !important;
}

button.btn-default.dim {
    box-shadow: inset 0 0 0 #b3b3b3, 0 5px 0 0 #b3b3b3, 0 10px 5px #999999 !important;
}

button.btn-default.dim:active {
    box-shadow: inset 0 0 0 #b3b3b3, 0 2px 0 0 #b3b3b3, 0 5px 3px #999999 !important;
}

button.btn-warning.dim {
    box-shadow: inset 0 0 0 #f79d3c, 0 5px 0 0 #f79d3c, 0 10px 5px #999999 !important;
}

button.btn-warning.dim:active {
    box-shadow: inset 0 0 0 #f79d3c, 0 2px 0 0 #f79d3c, 0 5px 3px #999999 !important;
}

button.btn-info.dim {
    box-shadow: inset 0 0 0 #1eacae, 0 5px 0 0 #1eacae, 0 10px 5px #999999 !important;
}

button.btn-info.dim:active {
    box-shadow: inset 0 0 0 #1eacae, 0 2px 0 0 #1eacae, 0 5px 3px #999999 !important;
}

button.btn-success.dim {
    box-shadow: inset 0 0 0 #1872ab, 0 5px 0 0 #1872ab, 0 10px 5px #999999 !important;
}

button.btn-success.dim:active {
    box-shadow: inset 0 0 0 #1872ab, 0 2px 0 0 #1872ab, 0 5px 3px #999999 !important;
}

button.btn-danger.dim {
    box-shadow: inset 0 0 0 #ea394c, 0 5px 0 0 #ea394c, 0 10px 5px #999999 !important;
}

button.btn-danger.dim:active {
    box-shadow: inset 0 0 0 #ea394c, 0 2px 0 0 #ea394c, 0 5px 3px #999999 !important;
}

button.dim:before {
    font-size: 50px;
    line-height: 1em;
    font-weight: normal;
    color: #fff;
    display: block;
    padding-top: 10px;
}

button.dim:active:before {
    top: 7px;
    font-size: 50px;
}

.btn:focus {
    outline: none !important;
}

.label {
    background-color: #d1dade;
    color: #5e5e5e;
    font-family: 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-weight: 600;
    padding: 3px 8px;
    text-shadow: none;
    border-radius: 0.25em;
    line-height: 1;
    white-space: nowrap;
}

.nav .label,
.ibox .label {
    font-size: 10px;
}

.badge {
    background-color: #d1dade;
    color: #5e5e5e;
    font-family: 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-size: 11px;
    font-weight: 600;
    padding-bottom: 4px;
    padding-left: 6px;
    padding-right: 6px;
    text-shadow: none;
    white-space: nowrap;
}

.label-primary,
.badge-primary {
    background-color: #1ab394;
    color: #FFFFFF;
}

.label-success,
.badge-success {
    background-color: #1c84c6;
    color: #FFFFFF;
}

.label-warning,
.badge-warning {
    background-color: #f8ac59;
    color: #FFFFFF;
}

.label-warning-light,
.badge-warning-light {
    background-color: #f8ac59;
    color: #ffffff;
}

.label-danger,
.badge-danger {
    background-color: #ed5565;
    color: #FFFFFF;
}

.label-info,
.badge-info {
    background-color: #23c6c8;
    color: #FFFFFF;
}

.label-inverse,
.badge-inverse {
    background-color: #262626;
    color: #FFFFFF;
}

.label-white,
.badge-white {
    background-color: #FFFFFF;
    color: #5E5E5E;
}

.label-white,
.badge-disable {
    background-color: #2A2E36;
    color: #8B91A0;
}

/* TOOGLE SWICH */
.onoffswitch {
    position: relative;
    width: 64px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
}

.onoffswitch-checkbox {
    display: none;
}

.onoffswitch-label {
    display: block;
    overflow: hidden;
    cursor: pointer;
    border: 2px solid #1ab394;
    border-radius: 2px;
}

.onoffswitch-inner {
    width: 200%;
    margin-left: -100%;
    -moz-transition: margin 0.3s ease-in 0s;
    -webkit-transition: margin 0.3s ease-in 0s;
    -o-transition: margin 0.3s ease-in 0s;
    transition: margin 0.3s ease-in 0s;
}

.onoffswitch-inner:before,
.onoffswitch-inner:after {
    float: left;
    width: 50%;
    height: 20px;
    padding: 0;
    line-height: 20px;
    font-size: 12px;
    color: white;
    font-family: Trebuchet, Arial, sans-serif;
    font-weight: bold;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

.onoffswitch-inner:before {
    content: "ON";
    padding-left: 10px;
    background-color: #1ab394;
    color: #FFFFFF;
}

.onoffswitch-inner:after {
    content: "OFF";
    padding-right: 10px;
    background-color: #FFFFFF;
    color: #999999;
    text-align: right;
}

.onoffswitch-switch {
    width: 20px;
    margin: 0;
    background: #FFFFFF;
    border: 2px solid #1ab394;
    border-radius: 2px;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 44px;
    -moz-transition: all 0.3s ease-in 0s;
    -webkit-transition: all 0.3s ease-in 0s;
    -o-transition: all 0.3s ease-in 0s;
    transition: all 0.3s ease-in 0s;
}

.onoffswitch-checkbox:checked + .onoffswitch-label .onoffswitch-inner {
    margin-left: 0;
}

.onoffswitch-checkbox:checked + .onoffswitch-label .onoffswitch-switch {
    right: 0;
}

.onoffswitch-checkbox:disabled + .onoffswitch-label .onoffswitch-inner:before {
    background-color: #919191;
}

.onoffswitch-checkbox:disabled + .onoffswitch-label,
.onoffswitch-checkbox:disabled + .onoffswitch-label .onoffswitch-switch {
    border-color: #919191;
}

/* Tags Input Plugin */
.bootstrap-tagsinput {
    border: 1px solid #e5e6e7;
    box-shadow: none;
}

/* PAGINATIN */
.pagination > .active > a,
.pagination > .active > span,
.pagination > .active > a:hover,
.pagination > .active > span:hover,
.pagination > .active > a:focus,
.pagination > .active > span:focus {
    border-color: #DDDDDD;
    cursor: default;
    z-index: 2;
}

.pagination > li > a,
.pagination > li > span {
    background-color: #FFFFFF;
    border: 1px solid #DDDDDD;
    color: inherit;
    float: left;
    line-height: 1.42857;
    margin-left: -1px;
    padding: 4px 10px;
    position: relative;
    text-decoration: none;
}

.page-item.active .page-link {
    background-color: #1ab394;
    border-color: #1ab394;
}

.page-link:focus {
    box-shadow: none;
}

.page-link:hover {
    color: #676a6c;
}

.pagination .footable-page.active a {
    background-color: #1ab394;
    border-color: #1ab394;
    color: white;
}

/* FLOT CHART  */
.fh-150 {
    height: 150px;
}

.fh-200 {
    height: 200px;
}

.h-150 {
    min-height: 150px;
}

.h-200 {
    min-height: 200px;
}

.h-300 {
    min-height: 300px;
}

.w-150 {
    min-width: 150px;
}

.w-200 {
    min-width: 200px;
}

.w-300 {
    min-width: 300px;
}

/* DATATABLES */
@media (max-width: 768px) {
    .html5buttons {
        float: none;
        margin-top: 10px;
    }
}

/* CIRCLE */
.img-circle {
    border-radius: 50%;
}

.btn-circle {
    width: 30px;
    height: 30px;
    padding: 6px 0;
    border-radius: 15px;
    text-align: center;
    font-size: 12px;
    line-height: 1.428571429;
}

.btn-circle.btn-lg {
    width: 50px;
    height: 50px;
    padding: 10px 16px;
    border-radius: 25px;
    font-size: 18px;
    line-height: 1.33;
}

.btn-circle.btn-xl {
    width: 70px;
    height: 70px;
    padding: 10px 16px;
    border-radius: 35px;
    font-size: 24px;
    line-height: 1.33;
}

.show-grid [class^="col-"] {
    padding-top: 10px;
    padding-bottom: 10px;
    border: 1px solid #ddd;
    background-color: #eee !important;
}

.show-grid {
    margin: 15px 0;
}

/* PEACE */
.pace {
    -webkit-pointer-events: none;
    pointer-events: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
}

.pace-inactive {
    display: none;
}

.pace .pace-progress {
    background: #1ab394;
    position: fixed;
    z-index: 2040;
    top: 0;
    right: 100%;
    width: 100%;
    height: 2px;
}

.pace-inactive {
    display: none;
}

/* Code */
pre {
    display: block;
    padding: 9.5px;
    margin: 0 0 10px;
    font-size: 13px;
    line-height: 1.42857143;
    color: #333;
    word-break: break-all;
    word-wrap: break-word;
    background-color: #eff2f3;
    border: 1px solid #d1dade;
    border-radius: 2px;
}

code,
kbd,
pre,
samp {
    font-family: Menlo, Monaco, Consolas, "Courier New", monospace;
}

.sidebar-panel {
    width: 220px;
    background: #ebebed;
    padding: 10px 20px;
    position: absolute;
    right: 0;
}

.sidebar-panel .feed-element img.rounded-circle {
    width: 32px;
    height: 32px;
}

.sidebar-panel .feed-element,
.media-body,
.sidebar-panel p {
    font-size: 12px;
}

.sidebar-panel .feed-element {
    margin-top: 20px;
    padding-bottom: 0;
}

.sidebar-panel .list-group {
    margin-bottom: 10px;
}

.sidebar-panel .list-group .list-group-item {
    padding: 5px 0;
    font-size: 12px;
    border: 0;
}

.sidebar-content .wrapper,
.wrapper.sidebar-content {
    padding-right: 230px !important;
}

.body-small .sidebar-content .wrapper,
.body-small .wrapper.sidebar-content {
    padding-right: 20px !important;
}

#right-sidebar {
    background-color: #fff;
    border-left: 1px solid #e7eaec;
    border-top: 1px solid #e7eaec;
    overflow: hidden;
    position: fixed;
    top: 60px;
    width: 260px !important;
    z-index: 1009;
    bottom: 0;
    right: -260px;
}

#right-sidebar.sidebar-open {
    right: 0;
}

#right-sidebar.sidebar-open.sidebar-top {
    top: 0;
    border-top: none;
}

.sidebar-container ul.nav-tabs {
    border: none;
}

.sidebar-container ul.nav-tabs.navs-4 li {
    width: 25%;
}

.sidebar-container ul.nav-tabs.navs-3 li {
    width: 33.3333%;
}

.sidebar-container ul.nav-tabs.navs-2 li {
    width: 50%;
}

.sidebar-container ul.nav-tabs li {
    border: none;
}

.sidebar-container ul.nav-tabs li a {
    border: none;
    padding: 12px 10px;
    margin: 0;
    border-radius: 0;
    background: #ffffff;
    color: #fff;
    text-align: center;
    border-right: 1px solid #334556;
}

.sidebar-container ul.nav-tabs li.active a {
    border: none;
    background: #f9f9f9;
    color: #676a6c;
    font-weight: bold;
}

.sidebar-container .nav-tabs > li.active > a:hover,
.sidebar-container .nav-tabs > li.active > a:focus {
    border: none;
}

.sidebar-container ul.sidebar-list {
    margin: 0;
    padding: 0;
}

.sidebar-container ul.sidebar-list li {
    border-bottom: 1px solid #e7eaec;
    padding: 15px 20px;
    list-style: none;
    font-size: 12px;
}

.sidebar-container .sidebar-message:nth-child(2n+2) {
    background: #f9f9f9;
}

.sidebar-container ul.sidebar-list li a {
    text-decoration: none;
    color: inherit;
}

.sidebar-container .sidebar-content {
    padding: 15px 20px;
    font-size: 12px;
}

.sidebar-container .sidebar-title {
    background: #f9f9f9;
    padding: 20px;
    border-bottom: 1px solid #e7eaec;
}

.sidebar-container .sidebar-title h3 {
    margin-bottom: 3px;
    padding-left: 2px;
}

.sidebar-container .tab-content h4 {
    margin-bottom: 5px;
}

.sidebar-container .sidebar-message > a > .float-left {
    margin-right: 10px;
}

.sidebar-container .sidebar-message > a {
    text-decoration: none;
    color: inherit;
}

.sidebar-container .sidebar-message {
    padding: 15px 20px;
}

.sidebar-container .sidebar-message .media-body {
    display: block;
    width: auto;
}

.sidebar-container .sidebar-message .message-avatar {
    height: 38px;
    width: 38px;
    border-radius: 50%;
}

.sidebar-container .setings-item {
    padding: 15px 20px;
    border-bottom: 1px solid #e7eaec;
}

body {
    font-family: "open sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
    background-color: #ffffff;
    font-size: 13px;
    color: #676a6c;
    overflow-x: hidden;
}

html,
body {
    height: 100%;
}

body.full-height-layout #wrapper,
body.full-height-layout #page-wrapper {
    height: 100%;
}

#page-wrapper {
    min-height: auto;
}

body.boxed-layout {
    /*background: url('patterns/shattered.png');*/
}

body.boxed-layout #wrapper {
    background-color: #ffffff;
    max-width: 1200px;
    margin: 0 auto;
    -webkit-box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.75);
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.75);
}

.top-navigation.boxed-layout #wrapper,
.boxed-layout #wrapper.top-navigation {
    max-width: 1300px !important;
}

.block {
    display: block;
}

.clear {
    display: block;
    overflow: hidden;
}

a {
    color: #337ab7;
    cursor: pointer;
}

a:hover,
a:focus {
    text-decoration: none;
}

.border-bottom {
    border-bottom: 1px solid #e7eaec !important;
}

.font-bold {
    font-weight: 600;
}

.font-normal {
    font-weight: 400;
}

.text-uppercase {
    text-transform: uppercase;
}

.font-italic {
    font-style: italic;
}

.b-r {
    border-right: 1px solid #e7eaec;
}

.hr-line-dashed {
    border-top: 1px dashed #e7eaec;
    color: #ffffff;
    background-color: #ffffff;
    height: 1px;
    margin: 20px 0;
}

.hr-line-solid {
    border-bottom: 1px solid #e7eaec;
    background-color: rgba(0, 0, 0, 0);
    border-style: solid !important;
    margin-top: 15px;
    margin-bottom: 15px;
}

video {
    width: 100% !important;
    height: auto !important;
}

/* WRAPPERS */
#wrapper {
    width: 100%;
    overflow-x: hidden;
}

.wrapper {
    padding: 0 20px;
}

.wrapper-content {
    padding: 20px 10px 40px;
}

#page-wrapper {
    padding: 0 15px;
    min-height: 568px;
    position: relative !important;
}

@media (min-width: 768px) {
    #page-wrapper {
        position: inherit;
        margin: 0 0 0 240px;
        min-height: 2002px;
    }
}

.title-action {
    text-align: right;
    padding-top: 30px;
}

.ibox-content h1,
.ibox-content h2,
.ibox-content h3,
.ibox-content h4,
.ibox-content h5,
.ibox-title h1,
.ibox-title h2,
.ibox-title h3,
.ibox-title h4,
.ibox-title h5 {
    margin-top: 5px;
}

ul.unstyled,
ol.unstyled {
    list-style: none outside none;
    margin-left: 0;
}

.big-icon {
    font-size: 160px !important;
    color: #e5e6e7;
}

/* FOOTER */
.footer {
    background: none repeat scroll 0 0 white;
    border-top: 1px solid #e7eaec;
    bottom: 0;
    left: 0;
    padding: 10px 20px;
    position: absolute;
    right: 0;
}

.footer.fixed_full {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1000;
    padding: 10px 20px;
    background: white;
    border-top: 1px solid #e7eaec;
}

.footer.fixed {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1000;
    padding: 10px 20px;
    background: white;
    border-top: 1px solid #e7eaec;
    margin-left: 220px;
}

body.mini-navbar .footer.fixed,
body.body-small.mini-navbar .footer.fixed {
    margin: 0 0 0 70px;
}

body.mini-navbar.canvas-menu .footer.fixed,
body.canvas-menu .footer.fixed {
    margin: 0 !important;
}

body.fixed-sidebar.body-small.mini-navbar .footer.fixed {
    margin: 0 0 0 220px;
}

body.body-small .footer.fixed {
    margin-left: 0;
}

/* PANELS */
.panel-title > .small,
.panel-title > .small > a,
.panel-title > a,
.panel-title > small,
.panel-title > small > a {
    color: inherit;
}

.page-heading {
    border-top: 0;
    padding: 0 10px 20px 10px;
}

.panel-heading h1,
.panel-heading h2 {
    margin-bottom: 5px;
}

.panel-body {
    padding: 15px;
}

/* Bootstrap 3.3.x panels */
.panel {
    margin-bottom: 20px;
    background-color: #fff;
    border: 1px solid transparent;
    border-radius: 4px;
}

.panel-heading {
    color: white;
    padding: 10px 15px;
    border-bottom: 1px solid transparent;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
}

.panel-footer {
    padding: 10px 15px;
    border-top: 1px solid #e7eaec;
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
}

.panel-default > .panel-heading {
    color: #333;
    background-color: #f5f5f5;
    border-color: #e7eaec;
}

.panel-default {
    border-color: #e7eaec;
}

.panel-group .panel + .panel {
    margin-top: 5px;
}

.panel-group .panel {
    margin-bottom: 0;
    border-radius: 4px;
}

/* PANELS */
.panel.blank-panel {
    background: none;
    margin: 0;
}

.blank-panel .panel-heading {
    padding-bottom: 0;
}

.nav-tabs > li > a {
    color: #818489;
    font-weight: 600;
    padding: 10px 20px 10px 25px;
}

.nav-tabs > li > a:hover,
.nav-tabs > li > a:focus {
    color: #676a6c;
}

.ui-tab .tab-content {
    padding: 20px 0;
}

/* GLOBAL  */
.no-padding {
    padding: 0 !important;
}

.no-borders {
    border: none !important;
}

.no-margins {
    margin: 0 !important;
}

.no-top-border {
    border-top: 0 !important;
}

.ibox-content.text-box {
    padding-bottom: 0;
    padding-top: 15px;
}

.border-left-right {
    border-left: 1px solid #e7eaec;
    border-right: 1px solid #e7eaec;
}

.border-top-bottom {
    border-top: 1px solid #e7eaec;
    border-bottom: 1px solid #e7eaec;
}

.border-left {
    border-left: 1px solid #e7eaec;
}

.border-right {
    border-right: 1px solid #e7eaec;
}

.border-top {
    border-top: 1px solid #e7eaec;
}

.border-bottom {
    border-bottom: 1px solid #e7eaec;
}

.border-size-sm {
    border-width: 3px;
}

.border-size-md {
    border-width: 6px;
}

.border-size-lg {
    border-width: 9px;
}

.border-size-xl {
    border-width: 12px;
}

.full-width {
    width: 100% !important;
}

.link-block {
    font-size: 12px;
    padding: 10px;
}

.nav.navbar-top-links .link-block a {
    font-size: 12px;
}

.link-block a {
    font-size: 10px;
    color: inherit;
}

body.mini-navbar .branding {
    display: none;
}

img.circle-border {
    border: 6px solid #FFFFFF;
    border-radius: 50%;
}

.branding {
    float: left;
    color: #FFFFFF;
    font-size: 18px;
    font-weight: 600;
    padding: 17px 20px;
    text-align: center;
    background-color: #1ab394;
}

.login-panel {
    margin-top: 25%;
}

.icons-box h3 {
    margin-top: 10px;
    margin-bottom: 10px;
}

.icons-box .infont a i {
    font-size: 25px;
    display: block;
    color: #676a6c;
}

.icons-box .infont a {
    color: #a6a8a9;
}

.icons-box .infont a {
    padding: 10px;
    margin: 1px;
    display: block;
}

.ui-draggable .ibox-title {
    cursor: move;
}

.breadcrumb {
    background-color: #ffffff;
    padding: 0;
    margin-bottom: 0;
}

.breadcrumb > li a {
    color: inherit;
}

.breadcrumb > .active {
    color: inherit;
}

code {
    background-color: #F9F2F4;
    border-radius: 4px;
    color: #ca4440;
    font-size: 90%;
    padding: 2px 4px;
    white-space: nowrap;
}

.ibox {
    clear: both;
    margin-bottom: 25px;
    margin-top: 0;
    padding: 0;
}

.ibox.collapsed .ibox-content {
    display: none;
}

.ibox.collapsed .fa.fa-chevron-up:before {
    content: "\f078";
}

.ibox.collapsed .fa.fa-chevron-down:before {
    content: "\f077";
}

.ibox:after,
.ibox:before {
    display: table;
}

.ibox-title {
    -moz-border-bottom-colors: none;
    -moz-border-left-colors: none;
    -moz-border-right-colors: none;
    -moz-border-top-colors: none;
    background-color: #ffffff;
    border-color: #e7eaec;
    border-image: none;
    border-style: solid solid none;
    border-width: 2px 0 0;
    color: inherit;
    margin-bottom: 0;
    padding: 15px 90px 8px 15px;
    min-height: 48px;
    position: relative;
    clear: both;
}

.ibox-content {
    background-color: #ffffff;
    color: inherit;
    padding: 15px 20px 20px 20px;
    border-color: #e7eaec;
    border-image: none;
    border-style: solid solid none;
    border-width: 1px 0;
}

.ibox-footer {
    color: inherit;
    border-top: 1px solid #e7eaec;
    font-size: 90%;
    background: #ffffff;
    padding: 10px 15px;
}

table.table-mail tr td {
    padding: 12px;
}

.table-mail .check-mail {
    padding-left: 20px;
}

.table-mail .mail-date {
    padding-right: 20px;
}

.star-mail,
.check-mail {
    width: 40px;
}

.unread td a,
.unread td {
    font-weight: 600;
    color: inherit;
}

.read td a,
.read td {
    font-weight: normal;
    color: inherit;
}

.unread td {
    background-color: #f9f8f8;
}

.ibox-content {
    clear: both;
}

.ibox-heading {
    background-color: #f3f6fb;
    border-bottom: none;
}

.ibox-heading h3 {
    font-weight: 200;
    font-size: 24px;
}

.ibox-title h5 {
    display: inline-block;
    font-size: 14px;
    margin: 0 0 7px;
    padding: 0;
    text-overflow: ellipsis;
    float: none;
}

.ibox-title .label {
    margin-left: 4px;
}

.ibox-title .pull-right {
    position: absolute;
    right: 15px;
    top: 15px;
}

.ibox-tools {
    display: block;
    float: none;
    margin-top: 0;
    position: absolute;
    top: 15px;
    right: 15px;
    padding: 0;
    text-align: right;
}

.ibox-tools a {
    cursor: pointer;
    margin-left: 5px;
    color: #c4c4c4 !important;
}

.ibox-tools a.btn-primary {
    color: #fff !important;
}

.ibox-tools .dropdown-menu > li > a {
    padding: 4px 10px;
    font-size: 12px;
    color: #676a6c !important;
}

.ibox .ibox-tools.open > .dropdown-menu {
    left: auto;
    right: 0;
}

.ibox-tools .dropdown-toggle::after {
    display: none;
}

.dropdown-item {
    width: auto;
}

.dropdown-item.active,
.dropdown-item:active {
    background-color: inherit;
    color: inherit;
}

/* BACKGROUNDS */
.gray-bg,
.bg-muted {
    background-color: #f3f3f4;
}

.white-bg {
    background-color: #ffffff;
}

.blue-bg,
.bg-success {
    background-color: #1c84c6 !important;
    color: #ffffff;
}

.navy-bg,
.bg-primary {
    background-color: #1ab394 !important;
    color: #ffffff;
}

.lazur-bg,
.bg-info {
    background-color: #23c6c8 !important;
    color: #ffffff;
}

.yellow-bg,
.bg-warning {
    background-color: #f8ac59 !important;
    color: #ffffff;
}

.red-bg,
.bg-danger {
    background-color: #ed5565 !important;
    color: #ffffff;
}

.black-bg {
    background-color: #262626;
}

.panel-primary {
    border-color: #1ab394;
}

.panel-primary > .panel-heading {
    background-color: #1ab394;
    border-color: #1ab394;
}

.panel-success {
    border-color: #1c84c6;
}

.panel-success > .panel-heading {
    background-color: #1c84c6;
    border-color: #1c84c6;
    color: #ffffff;
}

.panel-info {
    border-color: #23c6c8;
}

.panel-info > .panel-heading {
    background-color: #23c6c8;
    border-color: #23c6c8;
    color: #ffffff;
}

.panel-warning {
    border-color: #f8ac59;
}

.panel-warning > .panel-heading {
    background-color: #f8ac59;
    border-color: #f8ac59;
    color: #ffffff;
}

.panel-danger {
    border-color: #ed5565;
}

.panel-danger > .panel-heading {
    background-color: #ed5565;
    border-color: #ed5565;
    color: #ffffff;
}

.progress-bar {
    background-color: #1ab394;
}

.progress-small,
.progress-small .progress-bar {
    height: 10px;
}

.progress-small,
.progress-mini {
    margin-top: 5px;
}

.progress-mini,
.progress-mini .progress-bar {
    height: 5px;
    margin-bottom: 0;
}

.progress-bar-navy-light {
    background-color: #3dc7ab;
}

.progress-bar-success {
    background-color: #1c84c6;
}

.progress-bar-info {
    background-color: #23c6c8;
}

.progress-bar-warning {
    background-color: #f8ac59;
}

.progress-bar-danger {
    background-color: #ed5565;
}

.panel-title {
    font-size: inherit;
}

.jumbotron {
    border-radius: 6px;
    padding: 40px;
}

.jumbotron h1 {
    margin-top: 0;
}

/* COLORS */
.text-navy {
    color: #1ab394 !important;
}

.text-primary {
    color: inherit !important;
}

.text-success {
    color: #1c84c6 !important;
}

.text-info {
    color: #23c6c8 !important;
}

.text-warning {
    color: #f8ac59 !important;
}

.text-danger {
    color: #ed5565 !important;
}

.text-muted {
    color: #888888 !important;
}

.text-white {
    color: #ffffff;
}

.simple_tag {
    background-color: #f3f3f4;
    border: 1px solid #e7eaec;
    border-radius: 2px;
    color: inherit;
    font-size: 10px;
    margin-right: 5px;
    margin-top: 5px;
    padding: 5px 12px;
    display: inline-block;
}

.img-shadow {
    -webkit-box-shadow: 0 0 3px 0 #919191;
    -moz-box-shadow: 0 0 3px 0 #919191;
    box-shadow: 0 0 3px 0 #919191;
}

/* For handle diferent bg color in AngularJS version */
.dashboards\.dashboard_2 nav.navbar,
.dashboards\.dashboard_3 nav.navbar,
.mailbox\.inbox nav.navbar,
.mailbox\.email_view nav.navbar,
.mailbox\.email_compose nav.navbar,
.dashboards\.dashboard_4_1 nav.navbar,
.metrics nav.navbar,
.metrics\.index nav.navbar,
.dashboards\.dashboard_5 nav.navbar {
    background: #fff;
}

/* For handle diferent bg color in MVC version */
.Dashboard_2 .navbar.navbar-static-top,
.Dashboard_3 .navbar.navbar-static-top,
.Dashboard_4_1 .navbar.navbar-static-top,
.ComposeEmail .navbar.navbar-static-top,
.EmailView .navbar.navbar-static-top,
.Inbox .navbar.navbar-static-top,
.Metrics .navbar.navbar-static-top,
.Dashboard_5 .navbar.navbar-static-top {
    background: #fff;
}

a.close-canvas-menu {
    position: absolute;
    top: 10px;
    right: 15px;
    z-index: 1011;
    color: #a7b1c2;
}

a.close-canvas-menu:hover {
    color: #fff;
}

.close-canvas-menu {
    display: none;
}

.canvas-menu .close-canvas-menu {
    display: block;
}

.light-navbar .navbar.navbar-static-top {
    background-color: #ffffff;
}

/* FULL HEIGHT */
.full-height {
    height: 100%;
}

.fh-breadcrumb {
    height: calc(100% - 196px);
    margin: 0 -15px;
    position: relative;
}

.fh-no-breadcrumb {
    height: calc(100% - 99px);
    margin: 0 -15px;
    position: relative;
}

.fh-column {
    background: #fff;
    height: 100%;
    width: 240px;
    float: left;
}

.modal-backdrop {
    z-index: 2040 !important;
}

.modal {
    z-index: 2050 !important;
}

.spiner-example {
    height: 200px;
    padding-top: 70px;
}

/* MARGINS & PADDINGS */
.p-xxs {
    padding: 5px;
}

.p-xs {
    padding: 10px;
}

.p-sm {
    padding: 15px;
}

.p-m {
    padding: 20px;
}

.p-md {
    padding: 25px;
}

.p-lg {
    padding: 30px;
}

.p-xl {
    padding: 40px;
}

.p-w-xs {
    padding: 0 10px;
}

.p-w-sm {
    padding: 0 15px;
}

.p-w-m {
    padding: 0 20px;
}

.p-w-md {
    padding: 0 25px;
}

.p-w-lg {
    padding: 0 30px;
}

.p-w-xl {
    padding: 0 40px;
}

.p-h-xs {
    padding: 10px 0;
}

.p-h-sm {
    padding: 15px 0;
}

.p-h-m {
    padding: 20px 0;
}

.p-h-md {
    padding: 25px 0;
}

.p-h-lg {
    padding: 30px 0;
}

.p-h-xl {
    padding: 40px 0;
}

.m-xxs {
    margin: 2px 4px;
}

.m {
    margin: 15px;
}

.m-xs {
    margin: 5px;
}

.m-sm {
    margin: 10px;
}

.m-md {
    margin: 20px;
}

.m-lg {
    margin: 30px;
}

.m-xl {
    margin: 50px;
}

.m-n {
    margin: 0 !important;
}

.m-l-none {
    margin-left: 0;
}

.m-l-xs {
    margin-left: 5px;
}

.m-l-sm {
    margin-left: 10px;
}

.m-l {
    margin-left: 15px;
}

.m-l-md {
    margin-left: 20px;
}

.m-l-lg {
    margin-left: 30px;
}

.m-l-xl {
    margin-left: 40px;
}

.m-l-n-xxs {
    margin-left: -1px;
}

.m-l-n-xs {
    margin-left: -5px;
}

.m-l-n-sm {
    margin-left: -10px;
}

.m-l-n {
    margin-left: -15px;
}

.m-l-n-md {
    margin-left: -20px;
}

.m-l-n-lg {
    margin-left: -30px;
}

.m-l-n-xl {
    margin-left: -40px;
}

.m-t-none {
    margin-top: 0;
}

.m-t-xxs {
    margin-top: 1px;
}

.m-t-xs {
    margin-top: 5px;
}

.m-t-sm {
    margin-top: 10px;
}

.m-t {
    margin-top: 15px;
}

.m-t-md {
    margin-top: 20px;
}

.m-t-lg {
    margin-top: 30px;
}

.m-t-xl {
    margin-top: 40px;
}

.m-t-n-xxs {
    margin-top: -1px;
}

.m-t-n-xs {
    margin-top: -5px;
}

.m-t-n-sm {
    margin-top: -10px;
}

.m-t-n {
    margin-top: -15px;
}

.m-t-n-md {
    margin-top: -20px;
}

.m-t-n-lg {
    margin-top: -30px;
}

.m-t-n-xl {
    margin-top: -40px;
}

.m-r-none {
    margin-right: 0;
}

.m-r-xxs {
    margin-right: 1px;
}

.m-r-xs {
    margin-right: 5px;
}

.m-r-sm {
    margin-right: 10px;
}

.m-r {
    margin-right: 15px;
}

.m-r-md {
    margin-right: 20px;
}

.m-r-lg {
    margin-right: 30px;
}

.m-r-xl {
    margin-right: 40px;
}

.m-r-n-xxs {
    margin-right: -1px;
}

.m-r-n-xs {
    margin-right: -5px;
}

.m-r-n-sm {
    margin-right: -10px;
}

.m-r-n {
    margin-right: -15px;
}

.m-r-n-md {
    margin-right: -20px;
}

.m-r-n-lg {
    margin-right: -30px;
}

.m-r-n-xl {
    margin-right: -40px;
}

.m-b-none {
    margin-bottom: 0;
}

.m-b-xxs {
    margin-bottom: 1px;
}

.m-b-xs {
    margin-bottom: 5px;
}

.m-b-sm {
    margin-bottom: 10px;
}

.m-b {
    margin-bottom: 15px;
}

.m-b-md {
    margin-bottom: 20px;
}

.m-b-lg {
    margin-bottom: 30px;
}

.m-b-xl {
    margin-bottom: 40px;
}

.m-b-n-xxs {
    margin-bottom: -1px;
}

.m-b-n-xs {
    margin-bottom: -5px;
}

.m-b-n-sm {
    margin-bottom: -10px;
}

.m-b-n {
    margin-bottom: -15px;
}

.m-b-n-md {
    margin-bottom: -20px;
}

.m-b-n-lg {
    margin-bottom: -30px;
}

.m-b-n-xl {
    margin-bottom: -40px;
}

.space-15 {
    margin: 15px 0;
}

.space-20 {
    margin: 20px 0;
}

.space-25 {
    margin: 25px 0;
}

.space-30 {
    margin: 30px 0;
}

.img-sm {
    width: 32px;
    height: 32px;
}

.img-md {
    width: 64px;
    height: 64px;
}

.img-lg {
    width: 96px;
    height: 96px;
}

.b-r-xs {
    -webkit-border-radius: 1px;
    -moz-border-radius: 1px;
    border-radius: 1px;
}

.b-r-sm {
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
}

.b-r-md {
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    border-radius: 6px;
}

.b-r-lg {
    -webkit-border-radius: 12px;
    -moz-border-radius: 12px;
    border-radius: 12px;
}

.b-r-xl {
    -webkit-border-radius: 24px;
    -moz-border-radius: 24px;
    border-radius: 24px;
}

.fullscreen-ibox-mode .animated {
    animation: none;
}

body.fullscreen-ibox-mode {
    overflow-y: hidden;
}

.ibox.fullscreen {
    z-index: 2030;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: auto;
    margin-bottom: 0;
}

.ibox.fullscreen .collapse-link {
    display: none;
}

.ibox.fullscreen .ibox-content {
    min-height: calc(100% - 48px);
}

body.modal-open {
    padding-right: inherit !important;
}

_::-webkit-full-page-media,
_:future,
:root body.modal-open .wrapper-content.animated {
    -webkit-animation: none;
    -ms-animation-nam: none;
    animation: none;
}

body.modal-open .animated {
    animation-fill-mode: initial;
    z-index: inherit;
}

/* Show profile dropdown on fixed sidebar */
body.mini-navbar.fixed-sidebar .profile-element,
.block {
    display: block !important;
}

body.mini-navbar.fixed-sidebar .nav-header {
    padding: 33px 25px;
}

body.mini-navbar.fixed-sidebar .logo-element {
    display: none;
}

.fullscreen-video .animated {
    animation: none;
}

.list-inline > li {
    display: inline-block;
}

.custom-file-label {
    padding: .5rem .75rem;
}

.custom-file-label::after {
    padding: .5rem .75rem;
}

/* ERROR & LOGIN & LOCKSCREEN*/
.middle-box {
    max-width: 400px;
    z-index: 100;
    margin: 0 auto;
    padding-top: 40px;
}

.lockscreen.middle-box {
    width: 200px;
    padding-top: 110px;
}

.loginscreen.middle-box {
    width: 300px;
}

.loginColumns {
    max-width: 800px;
    margin: 0 auto;
    padding: 100px 20px 20px 20px;
}

.passwordBox {
    max-width: 460px;
    margin: 0 auto;
    padding: 100px 20px 20px 20px;
}

.logo-name {
    color: #e6e6e6;
    font-size: 180px;
    font-weight: 800;
    letter-spacing: -10px;
    margin-bottom: 0;
}

.middle-box h1 {
    font-size: 170px;
}

.wrapper .middle-box {
    margin-top: 140px;
}

.lock-word {
    z-index: 10;
    position: absolute;
    top: 110px;
    left: 50%;
    margin-left: -470px;
}

.lock-word span {
    font-size: 100px;
    font-weight: 600;
    color: #e9e9e9;
    display: inline-block;
}

.lock-word .first-word {
    margin-right: 160px;
}

/* DASBOARD */
.dashboard-header {
    border-top: 0;
    padding: 20px 20px 20px 20px;
}

.dashboard-header h2 {
    margin-top: 10px;
    font-size: 26px;
}

.fist-item {
    border-top: none !important;
}

.statistic-box {
    margin-top: 40px;
}

.dashboard-header .list-group-item span.label {
    margin-right: 10px;
}

.list-group.clear-list .list-group-item {
    border-top: 1px solid #e7eaec;
    border-bottom: 0;
    border-right: 0;
    border-left: 0;
    padding: 10px 0;
}

ul.clear-list:first-child {
    border-top: none !important;
}

/* Intimeline */
.timeline-item .date i {
    position: absolute;
    top: 0;
    right: 0;
    padding: 5px;
    width: 30px;
    text-align: center;
    border-top: 1px solid #e7eaec;
    border-bottom: 1px solid #e7eaec;
    border-left: 1px solid #e7eaec;
    background: #f8f8f8;
}

.timeline-item .date {
    text-align: right;
    width: 110px;
    position: relative;
    padding-top: 30px;
}

.timeline-item .content {
    border-left: 1px solid #e7eaec;
    border-top: 1px solid #e7eaec;
    padding-top: 10px;
    min-height: 100px;
}

.timeline-item .content:hover {
    background: #f6f6f6;
}

/* PIN BOARD */
ul.notes li,
ul.tag-list li {
    list-style: none;
}

ul.notes li h4 {
    margin-top: 20px;
    font-size: 16px;
}

ul.notes li div {
    text-decoration: none;
    color: #000;
    background: #ffc;
    display: block;
    height: 140px;
    width: 140px;
    padding: 1em;
    position: relative;
}

ul.notes li div small {
    position: absolute;
    top: 5px;
    right: 5px;
    font-size: 10px;
}

ul.notes li div a {
    position: absolute;
    right: 10px;
    bottom: 10px;
    color: inherit;
}

ul.notes li {
    margin: 10px 40px 50px 0;
    float: left;
}

ul.notes li div p {
    font-size: 12px;
}

ul.notes li div {
    text-decoration: none;
    color: #000;
    background: #ffc;
    display: block;
    height: 140px;
    width: 140px;
    padding: 1em;
    /* Firefox */
    -moz-box-shadow: 5px 5px 2px #212121;
    /* Safari+Chrome */
    -webkit-box-shadow: 5px 5px 2px rgba(33, 33, 33, 0.7);
    /* Opera */
    box-shadow: 5px 5px 2px rgba(33, 33, 33, 0.7);
}

ul.notes li div {
    -webkit-transform: rotate(-6deg);
    -o-transform: rotate(-6deg);
    -moz-transform: rotate(-6deg);
    -ms-transform: rotate(-6deg);
}

ul.notes li:nth-child(even) div {
    -o-transform: rotate(4deg);
    -webkit-transform: rotate(4deg);
    -moz-transform: rotate(4deg);
    -ms-transform: rotate(4deg);
    position: relative;
    top: 5px;
}

ul.notes li:nth-child(3n) div {
    -o-transform: rotate(-3deg);
    -webkit-transform: rotate(-3deg);
    -moz-transform: rotate(-3deg);
    -ms-transform: rotate(-3deg);
    position: relative;
    top: -5px;
}

ul.notes li:nth-child(5n) div {
    -o-transform: rotate(5deg);
    -webkit-transform: rotate(5deg);
    -moz-transform: rotate(5deg);
    -ms-transform: rotate(5deg);
    position: relative;
    top: -10px;
}

ul.notes li div:hover,
ul.notes li div:focus {
    -webkit-transform: scale(1.1);
    -moz-transform: scale(1.1);
    -o-transform: scale(1.1);
    -ms-transform: scale(1.1);
    position: relative;
    z-index: 5;
}

ul.notes li div {
    text-decoration: none;
    color: #000;
    background: #ffc;
    display: block;
    height: 210px;
    width: 210px;
    padding: 1em;
    -moz-box-shadow: 5px 5px 7px #212121;
    -webkit-box-shadow: 5px 5px 7px rgba(33, 33, 33, 0.7);
    box-shadow: 5px 5px 7px rgba(33, 33, 33, 0.7);
    -moz-transition: -moz-transform 0.15s linear;
    -o-transition: -o-transform 0.15s linear;
    -webkit-transition: -webkit-transform 0.15s linear;
}

.landing-page {
    /* CUSTOMIZE THE NAVBAR
  -------------------------------------------------- */
    /* Flip around the padding for proper display in narrow viewports */
    /* BACKGROUNDS SLIDER
  -------------------------------------------------- */
    /* CUSTOMIZE THE CAROUSEL
  -------------------------------------------------- */
    /* Carousel base class */
    /* Since positioning the image, we need to help out the caption */
    /* Declare heights because of positioning of img element */
    /* Sections
  ------------------------- */
    /* Buttons - only primary custom button
  ------------------------- */
    /* RESPONSIVE CSS
  -------------------------------------------------- */
}

.landing-page button:focus {
    outline: 0;
}

.landing-page .container {
    overflow: hidden;
}

.landing-page span.navy {
    color: #1ab394;
}

.landing-page p.text-color {
    color: #676a6c;
}

.landing-page a.navy-link {
    color: #1ab394;
    text-decoration: none;
}

.landing-page a.navy-link:hover {
    color: #179d82;
}

.landing-page section p {
    color: #aeaeae;
    font-size: 13px;
}

.landing-page address {
    font-size: 13px;
}

.landing-page h1 {
    margin-top: 10px;
    font-size: 30px;
    font-weight: 200;
}

.landing-page .navy-line {
    width: 60px;
    height: 1px;
    margin: 60px auto 0;
    border-bottom: 2px solid #1ab394;
}

.landing-page .navbar {
    padding: 0 1rem;
}

.landing-page .navbar-wrapper {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 200;
}

.landing-page .navbar-wrapper > .container {
    padding-right: 0;
    padding-left: 0;
}

.landing-page .navbar-wrapper .navbar {
    padding-right: 15px;
    padding-left: 15px;
}

.landing-page .navbar-default.navbar-scroll {
    background-color: #fff;
    border-color: #fff;
    padding: 15px 0;
}

.landing-page .navbar-default {
    background-color: transparent;
    border-color: transparent;
    transition: all 0.3s ease-in-out 0s;
}

.landing-page .navbar-default .nav li a {
    color: #fff;
    font-family: 'Open Sans', helvetica, arial, sans-serif;
    font-weight: 700;
    letter-spacing: 1px;
    text-transform: uppercase;
    font-size: 14px;
}

.landing-page .navbar-nav > li > a {
    padding-top: 25px;
    border-top: 6px solid transparent;
}

.landing-page .navbar-default .navbar-nav > .active > a,
.landing-page .navbar-default .navbar-nav > .active > a:hover {
    background: transparent;
    color: #fff;
    border-top: 6px solid #1ab394;
}

.landing-page .navbar-default .navbar-nav > li > a:hover,
.landing-page .navbar-default .navbar-nav > li > a:focus {
    color: #1ab394;
    background: inherit;
}

.landing-page .navbar-default .navbar-nav > .active > a:focus {
    background: transparent;
    color: #fff;
}

.landing-page .navbar-default .navbar-nav > .active > a:focus {
    background: transparent;
    color: #ffffff;
}

.landing-page .navbar-default.navbar-scroll .navbar-nav > .active > a:focus {
    background: transparent;
    color: inherit;
}

.landing-page .navbar-default .navbar-brand:hover,
.landing-page .navbar-default .navbar-brand:focus {
    background: #179d82;
    color: #fff;
}

.landing-page .navbar-default .navbar-brand {
    color: #fff;
    height: auto;
    display: block;
    font-size: 14px;
    background: #1ab394;
    padding: 15px 20px 15px 20px;
    border-radius: 0 0 5px 5px;
    font-weight: 700;
    transition: all 0.3s ease-in-out 0s;
    margin-top: -16px;
}

.landing-page .navbar-scroll.navbar-default .nav li a {
    color: #676a6c;
}

.landing-page .navbar-scroll.navbar-default .nav li a:hover {
    color: #1ab394;
}

.landing-page .navbar-wrapper .navbar.navbar-scroll {
    padding-top: 0;
    padding-bottom: 5px;
    border-bottom: 1px solid #e7eaec;
    border-radius: 0;
}

.landing-page .nav.navbar-right {
    flex-direction: row;
}

.landing-page .nav > li.active {
    border: none;
    background: inherit;
}

.landing-page .nav > li > a {
    padding: 25px 10px 15px 10px;
}

.landing-page .navbar-scroll .navbar-nav > li > a {
    padding: 20px 10px;
}

.landing-page .navbar-default .navbar-nav > li .nav-link.active,
.landing-page .navbar-default .navbar-nav > li .nav-link.active:hover {
    border-top: 6px solid #1ab394;
}

.landing-page .navbar-fixed-top {
    border: none !important;
}

.landing-page .navbar-fixed-top.navbar-scroll {
    border-bottom: 1px solid #e7eaec !important;
}

.landing-page .navbar.navbar-scroll .navbar-brand {
    margin-top: 5px;
    border-radius: 5px;
    font-size: 12px;
    padding: 10px;
    height: auto;
}

.landing-page .header-back {
    height: 470px;
    width: 100%;
}

.landing-page .header-back.one {
    /*background: url('../img/landing/header_one.jpg') 50% 0 no-repeat;*/
}

.landing-page .header-back.two {
    /*background: url('../img/landing/header_two.jpg') 50% 0 no-repeat;*/
}

.landing-page .carousel {
    height: 470px;
}

.landing-page .carousel-caption {
    z-index: 10;
}

.landing-page .carousel .item {
    height: 470px;
    background-color: #777;
}

.landing-page .carousel-inner > .item > img {
    position: absolute;
    top: 0;
    left: 0;
    min-width: 100%;
    height: 470px;
}

.landing-page .carousel-fade .carousel-inner .item {
    opacity: 0;
    -webkit-transition-property: opacity;
    transition-property: opacity;
}

.landing-page .carousel-fade .carousel-inner .active {
    opacity: 1;
}

.landing-page .carousel-fade .carousel-inner .active.left,
.landing-page .carousel-fade .carousel-inner .active.right {
    left: 0;
    opacity: 0;
    z-index: 1;
}

.landing-page .carousel-fade .carousel-inner .next.left,
.landing-page .carousel-fade .carousel-inner .prev.right {
    opacity: 1;
}

.landing-page .carousel-fade .carousel-control {
    z-index: 2;
}

.landing-page .carousel-control.left,
.landing-page .carousel-control.right {
    background: none;
}

.landing-page .carousel-control {
    width: 6%;
}

.landing-page .carousel-inner .container {
    position: relative;
    overflow: visible;
}

.landing-page .carousel-inner {
    overflow: visible;
}

.landing-page .carousel-caption {
    position: absolute;
    top: 100px;
    left: 0;
    bottom: auto;
    right: auto;
    text-align: left;
}

.landing-page .carousel-caption {
    position: absolute;
    top: 100px;
    left: 0;
    bottom: auto;
    right: auto;
    text-align: left;
}

.landing-page .carousel-caption.blank {
    top: 140px;
}

.landing-page .carousel-image {
    position: absolute;
    right: 10px;
    top: 150px;
}

.landing-page .carousel-indicators {
    padding-right: 60px;
}

.landing-page .carousel-caption h1 {
    font-weight: 700;
    font-size: 38px;
    text-transform: uppercase;
    text-shadow: none;
    letter-spacing: -1.5px;
}

.landing-page .carousel-caption p {
    font-weight: 700;
    text-transform: uppercase;
    text-shadow: none;
}

.landing-page .caption-link {
    color: #fff;
    margin-left: 10px;
    text-transform: capitalize;
    font-weight: 400;
}

.landing-page .caption-link:hover {
    text-decoration: none;
    color: inherit;
}

.landing-page .services {
    padding-top: 60px;
}

.landing-page .services h2 {
    font-size: 20px;
    letter-spacing: -1px;
    font-weight: 600;
    text-transform: uppercase;
}

.landing-page .features-block {
    margin-top: 40px;
}

.landing-page .features-text {
    margin-top: 40px;
}

.landing-page .features small {
    color: #1ab394;
}

.landing-page .features h2 {
    font-size: 18px;
    margin-top: 5px;
}

.landing-page .features-text-alone {
    margin: 40px 0;
}

.landing-page .features-text-alone h1 {
    font-weight: 200;
}

.landing-page .features-icon {
    color: #1ab394;
    font-size: 40px;
}

.landing-page .navy-section {
    margin-top: 60px;
    background: #1ab394;
    color: #fff;
    padding: 20px 0;
}

.landing-page .gray-section {
    background: #f4f4f4;
    margin-top: 60px;
}

.landing-page .team-member {
    text-align: center;
}

.landing-page .team-member img {
    margin: auto;
}

.landing-page .social-icon a {
    background: #1ab394;
    color: #fff;
    padding: 4px 8px;
    height: 28px;
    width: 28px;
    display: block;
    border-radius: 50px;
}

.landing-page .social-icon a:hover {
    background: #179d82;
}

.landing-page .img-small {
    height: 88px;
    width: 88px;
}

.landing-page .pricing-plan {
    margin: 20px 30px 0 30px;
    border-radius: 4px;
}

.landing-page .pricing-plan.selected {
    transform: scale(1.1);
    background: #f4f4f4;
}

.landing-page .pricing-plan li {
    padding: 10px 16px;
    border-top: 1px solid #e7eaec;
    text-align: center;
    color: #aeaeae;
}

.landing-page .pricing-plan .pricing-price span {
    font-weight: 700;
    color: #1ab394;
}

.landing-page li.pricing-desc {
    font-size: 13px;
    border-top: none;
    padding: 20px 16px;
}

.landing-page li.pricing-title {
    background: #1ab394;
    color: #fff;
    padding: 10px;
    border-radius: 4px 4px 0 0;
    font-size: 22px;
    font-weight: 600;
}

.landing-page .testimonials {
    padding-top: 80px;
    padding-bottom: 90px;
    background-color: #1ab394;
    /*background-image: url('../img/landing/avatar_all.png');*/
}

.landing-page .big-icon {
    font-size: 56px !important;
}

.landing-page .features .big-icon {
    color: #1ab394 !important;
}

.landing-page .contact {
    /*background-image: url('../img/landing/word_map.png');*/
    background-position: 50% 50%;
    background-repeat: no-repeat;
    margin-top: 60px;
}

.landing-page section.timeline {
    padding-bottom: 30px;
}

.landing-page section.comments {
    padding-bottom: 80px;
}

.landing-page .comments-avatar {
    margin-top: 25px;
    margin-left: 22px;
    margin-bottom: 25px;
}

.landing-page .comments-avatar .commens-name {
    font-weight: 600;
    font-size: 14px;
}

.landing-page .comments-avatar img {
    width: 42px;
    height: 42px;
    border-radius: 50%;
    margin-right: 10px;
}

.landing-page .bubble {
    position: relative;
    height: 120px;
    padding: 20px;
    background: #FFFFFF;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
    font-style: italic;
    font-size: 14px;
}

.landing-page .bubble:after {
    content: '';
    position: absolute;
    border-style: solid;
    border-width: 15px 14px 0;
    border-color: #FFFFFF transparent;
    display: block;
    width: 0;
    z-index: 1;
    bottom: -15px;
    left: 30px;
}

.landing-page .btn-primary.btn-outline:hover,
.landing-page .btn-success.btn-outline:hover,
.landing-page .btn-info.btn-outline:hover,
.landing-page .btn-warning.btn-outline:hover,
.landing-page .btn-danger.btn-outline:hover {
    color: #fff;
}

.landing-page .btn-primary {
    background-color: #1ab394;
    border-color: #1ab394;
    color: #FFFFFF;
    font-size: 14px;
    padding: 10px 20px;
    font-weight: 600;
}

.landing-page .btn-primary:hover,
.landing-page .btn-primary:focus,
.landing-page .btn-primary:active,
.landing-page .btn-primary.active,
.landing-page .open .dropdown-toggle.btn-primary {
    background-color: #179d82;
    border-color: #179d82;
    color: #FFFFFF;
}

.landing-page .btn-primary:active,
.landing-page .btn-primary.active,
.landing-page .open .dropdown-toggle.btn-primary {
    background-image: none;
}

.landing-page .btn-primary.disabled,
.landing-page .btn-primary.disabled:hover,
.landing-page .btn-primary.disabled:focus,
.landing-page .btn-primary.disabled:active,
.landing-page .btn-primary.disabled.active,
.landing-page .btn-primary[disabled],
.landing-page .btn-primary[disabled]:hover,
.landing-page .btn-primary[disabled]:focus,
.landing-page .btn-primary[disabled]:active,
.landing-page .btn-primary.active[disabled],
.landing-page fieldset[disabled] .btn-primary,
.landing-page fieldset[disabled] .btn-primary:hover,
.landing-page fieldset[disabled] .btn-primary:focus,
.landing-page fieldset[disabled] .btn-primary:active,
.landing-page fieldset[disabled] .btn-primary.active {
    background-color: #1dc5a3;
    border-color: #1dc5a3;
}

@media (min-width: 768px) {
    .landing-page {
        /* Navbar positioning foo */
        /* The navbar becomes detached from the top, so we round the corners */
        /* Bump up size of carousel content */
    }

    .landing-page .navbar-wrapper .container {
        padding-right: 15px;
        padding-left: 15px;
    }

    .landing-page .navbar-wrapper .navbar {
        padding-right: 0;
        padding-left: 0;
    }

    .landing-page .navbar-wrapper .navbar {
        border-radius: 4px;
    }

    .landing-page .carousel-caption p {
        margin-bottom: 20px;
        font-size: 14px;
        line-height: 1.4;
    }

    .landing-page .featurette-heading {
        font-size: 50px;
    }
}

@media (max-width: 992px) {
    .landing-page .carousel-image {
        display: none;
    }
}

@media (max-width: 768px) {
    .landing-page .carousel-caption,
    .landing-page .carousel-caption.blank {
        left: 5%;
        top: 80px;
    }

    .landing-page .carousel-caption h1 {
        font-size: 28px;
    }

    .landing-page .navbar.navbar-scroll .navbar-brand {
        margin-top: 6px;
    }

    .landing-page .navbar-default {
        background-color: #fff;
        border-color: #fff;
        padding: 15px 0;
    }

    .landing-page .navbar-default .navbar-nav > .active > a:focus {
        background: transparent;
        color: inherit;
    }

    .landing-page .navbar-default .nav li a {
        color: #676a6c;
    }

    .landing-page .navbar-default .nav li a:hover {
        color: #1ab394;
    }

    .landing-page .navbar-wrapper .navbar {
        padding-top: 0;
        padding-bottom: 5px;
        border-bottom: 1px solid #e7eaec;
        border-radius: 0;
    }

    .landing-page .nav > li > a {
        padding: 10px 10px 15px 10px;
    }

    .landing-page .navbar-nav > li > a {
        padding: 20px 10px;
    }

    .landing-page .navbar .navbar-brand {
        margin-top: 5px;
        border-radius: 5px;
        font-size: 12px;
        padding: 10px;
        height: auto;
    }

    .landing-page .navbar-wrapper .navbar {
        padding-left: 15px;
        padding-right: 5px;
    }

    .landing-page .navbar-default .navbar-nav > .active > a,
    .landing-page .navbar-default .navbar-nav > .active > a:hover {
        color: inherit;
    }

    .landing-page .carousel-control {
        display: none;
    }
}

@media (min-width: 992px) {
    .landing-page .featurette-heading {
        margin-top: 120px;
    }
}

@media (max-width: 768px) {
    .landing-page .navbar .navbar-header {
        display: block;
        float: none;
    }

    .landing-page .navbar .navbar-header .navbar-toggle {
        background-color: #ffffff;
        padding: 9px 10px;
        border: none;
    }

    .landing-page .nav.navbar-right {
        flex-direction: column;
    }
}

.landing-page .navbar-toggle {
    color: #ddd;
    float: right;
}

.landing-page .navbar-toggle i {
    font-size: 24px;
}

/*
 *
 *   SKIN 1 - INSPINIA - Responsive Admin Theme
 *   NAME - Blue light
 *
*/
.skin-1 .minimalize-styl-2 {
    margin: 14px 5px 5px 30px;
}

.skin-1 .navbar-top-links li:last-child {
    margin-right: 30px;
}

.skin-1.fixed-nav .minimalize-styl-2 {
    margin: 14px 5px 5px 15px;
}

.skin-1 .spin-icon {
    background: #0e9aef !important;
}

.skin-1 .nav-header {
    background-color: #0e9aef;
    /*background-image: url('patterns/header-profile-skin-1.png');*/
}

.skin-1.mini-navbar .nav-second-level {
    background: #3e495f;
}

.skin-1 .breadcrumb {
    background: transparent;
}

.skin-1 .page-heading {
    border: none;
}

.skin-1 .nav > li.active {
    background: #3a4459;
}

.skin-1 .nav > li > a {
    color: #9ea6b9;
}

.skin-1 ul.nav-second-level {
    background-color: inherit;
}

.skin-1 .nav > li.active > a {
    color: #818489;
}

.skin-1 .navbar-minimalize {
    background: #0e9aef;
    border-color: #0e9aef;
}

body.skin-1 {
    background: #3e495f;
}

.skin-1 .navbar-static-top {
    background: #ffffff;
}

.skin-1 .dashboard-header {
    background: transparent;
    border-bottom: none !important;
    border-top: none;
    padding: 20px 30px 10px 30px;
}

.fixed-nav.skin-1 .navbar-fixed-top {
    background: #fff;
}

.skin-1 .wrapper-content {
    padding: 30px 15px;
}

.skin-1 #page-wrapper {
    background: #f4f6fa;
}

.skin-1 .ibox-title,
.skin-1 .ibox-content {
    border-width: 1px;
}

.skin-1 .ibox-content:last-child {
    border-style: solid solid solid solid;
}

.skin-1 .nav > li.active {
    border: none;
}

.skin-1 .nav-header {
    padding: 35px 25px 25px 25px;
}

.skin-1 .nav-header a.dropdown-toggle {
    color: #fff;
    margin-top: 10px;
}

.skin-1 .nav-header a.dropdown-toggle .text-muted {
    color: #fff;
    opacity: 0.8;
}

.skin-1 .profile-element {
    text-align: center;
}

.skin-1 .rounded-circle {
    border-radius: 5px;
}

.skin-1 .navbar-default .nav > li > a:hover,
.skin-1 .navbar-default .nav > li > a:focus {
    background: #3a4459;
    color: #fff;
}

.skin-1 .nav.nav-tabs > li.active > a {
    color: #555;
}

.skin-1 .nav.nav-tabs > li.active {
    background: transparent;
}

/*
 *
 *   SKIN 2 - INSPINIA - Responsive Admin Theme
 *   NAME - Inspinia Ultra
 *
*/
body.skin-2 {
    color: #565758 !important;
}

.skin-2 .minimalize-styl-2 {
    margin: 14px 5px 5px 25px;
}

.skin-2 .navbar-top-links li:last-child {
    margin-right: 30px;
}

.skin-2 .spin-icon {
    background: #23c6c8 !important;
}

.skin-2 .nav-header {
    background-color: #23c6c8;
    /*background-image: url('patterns/header-profile-skin-2.png');*/
}

.skin-2.mini-navbar .nav-second-level {
    background: #ededed;
}

.skin-2 .breadcrumb {
    background: transparent;
}

.skin-2.fixed-nav .minimalize-styl-2 {
    margin: 14px 5px 5px 15px;
}

.skin-2 .page-heading {
    border: none;
    background: rgba(255, 255, 255, 0.7);
}

.skin-2 ul.nav-second-level {
    background-color: inherit;
}

.skin-2 .nav > li.active {
    background: #e0e0e0;
}

.skin-2 .logo-element {
    padding: 17px 0;
}

.skin-2 .nav > li > a,
.skin-2 .welcome-message {
    color: #edf6ff;
}

.skin-2 #top-search::-moz-placeholder {
    color: #edf6ff;
    opacity: 0.5;
}

.skin-2 #side-menu > li > a,
.skin-2 .nav.nav-second-level > li > a {
    color: #586b7d;
}

.skin-2 .nav > li.active > a {
    color: #213a53;
}

.skin-2.mini-navbar .nav-header {
    background: #213a53;
}

.skin-2 .navbar-minimalize {
    background: #23c6c8;
    border-color: #23c6c8;
}

.skin-2 .border-bottom {
    border-bottom: none !important;
}

.skin-2 #top-search {
    color: #fff;
}

body.skin-2 #wrapper {
    background-color: #ededed;
}

.skin-2 .navbar-static-top {
    background: #213a53;
}

.fixed-nav.skin-2 .navbar-fixed-top {
    background: #213a53;
    border-bottom: none !important;
}

.skin-2 .nav-header {
    padding: 30px 25px 30px 25px;
}

.skin-2 .dashboard-header {
    background: rgba(255, 255, 255, 0.4);
    border-bottom: none !important;
    border-top: none;
    padding: 20px 30px 20px 30px;
}

.skin-2 .wrapper-content {
    padding: 30px 15px;
}

.skin-2 .dashoard-1 .wrapper-content {
    padding: 0 30px 25px 30px;
}

.skin-2 .ibox-title {
    background: rgba(255, 255, 255, 0.7);
    border: none;
    margin-bottom: 1px;
}

.skin-2 .ibox-content {
    background: rgba(255, 255, 255, 0.4);
    border: none !important;
}

.skin-2 #page-wrapper {
    background: #f6f6f6;
    background: -webkit-radial-gradient(center, ellipse cover, #f6f6f6 20%, #d5d5d5 100%);
    background: -o-radial-gradient(center, ellipse cover, #f6f6f6 20%, #d5d5d5 100%);
    background: -ms-radial-gradient(center, ellipse cover, #f6f6f6 20%, #d5d5d5 100%);
    background: radial-gradient(ellipse at center, #f6f6f6 20%, #d5d5d5 100%);
    -ms-filter: "progid:DXImageTransform.Microsoft.gradient(startColorstr=#f6f6f6, endColorstr=#d5d5d5)";
}

.skin-2 .ibox-title,
.skin-2 .ibox-content {
    border-width: 1px;
}

.skin-2 .ibox-content:last-child {
    border-style: solid solid solid solid;
}

.skin-2 .nav > li.active {
    border: none;
}

.skin-2 .nav-header a.dropdown-toggle {
    color: #edf6ff;
    margin-top: 10px;
}

.skin-2 .nav-header a.dropdown-toggle .text-muted {
    color: #edf6ff;
    opacity: 0.8;
}

.skin-2 .rounded-circle {
    border-radius: 10px;
}

.skin-2 .nav.navbar-top-links > li > a:hover,
.skin-2 .nav.navbar-top-links > li > a:focus {
    background: #1a2d41;
}

.skin-2 .navbar-default .nav > li > a:hover,
.skin-2 .navbar-default .nav > li > a:focus {
    background: #e0e0e0;
    color: #213a53;
}

.skin-2 .nav.nav-tabs > li.active > a {
    color: #555;
}

.skin-2 .nav.nav-tabs > li.active {
    background: transparent;
}

/*
 *
 *   SKIN 3 - INSPINIA - Responsive Admin Theme
 *   NAME - Yellow/purple
 *
*/
.skin-3 .minimalize-styl-2 {
    margin: 14px 5px 5px 30px;
}

.skin-3 .navbar-top-links li:last-child {
    margin-right: 30px;
}

.skin-3.fixed-nav .minimalize-styl-2 {
    margin: 14px 5px 5px 15px;
}

.skin-3 .spin-icon {
    background: #ecba52 !important;
}

body.boxed-layout.skin-3 #wrapper {
    background: #3e2c42;
}

.skin-3 .nav-header {
    background-color: #ecba52;
    /*background-image: url('patterns/header-profile-skin-3.png');*/
}

.skin-3.mini-navbar .nav-second-level {
    background: #3e2c42;
}

.skin-3 .breadcrumb {
    background: transparent;
}

.skin-3 .page-heading {
    border: none;
}

.skin-3 ul.nav-second-level {
    background-color: inherit;
}

.skin-3 .nav > li.active {
    background: #38283c;
}

.fixed-nav.skin-3 .navbar-fixed-top {
    background: #fff;
}

.skin-3 .nav > li > a {
    color: #948b96;
}

.skin-3 .nav > li.active > a {
    color: #818489;
}

.skin-3 .navbar-minimalize {
    background: #ecba52;
    border-color: #ecba52;
}

body.skin-3 {
    background: #3e2c42;
}

.skin-3 .navbar-static-top {
    background: #ffffff;
}

.skin-3 .dashboard-header {
    background: transparent;
    border-bottom: none !important;
    border-top: none;
    padding: 20px 30px 10px 30px;
}

.skin-3 .wrapper-content {
    padding: 30px 15px;
}

.skin-3 #page-wrapper {
    background: #f4f6fa;
}

.skin-3 .ibox-title,
.skin-3 .ibox-content {
    border-width: 1px;
}

.skin-3 .ibox-content:last-child {
    border-style: solid solid solid solid;
}

.skin-3 .nav > li.active {
    border: none;
}

.skin-3 .nav-header {
    padding: 35px 25px 25px 25px;
}

.skin-3 .nav-header a.dropdown-toggle {
    color: #fff;
    margin-top: 10px;
}

.skin-3 .nav-header a.dropdown-toggle .text-muted {
    color: #fff;
    opacity: 0.8;
}

.skin-3 .profile-element {
    text-align: center;
}

.skin-3 .rounded-circle {
    border-radius: 5px;
}

.skin-3 .navbar-default .nav > li > a:hover,
.skin-3 .navbar-default .nav > li > a:focus {
    background: #38283c;
    color: #fff;
}

.skin-3 .nav.nav-tabs > li.active > a {
    color: #555;
}

.skin-3 .nav.nav-tabs > li.active {
    background: transparent;
}

body.md-skin {
    font-family: "Roboto", "Helvetica Neue", Helvetica, Arial, sans-serif;
    background-color: #ffffff;
}

.md-skin .nav-header {
    /*background: url("patterns/4.png") no-repeat;*/
}

.md-skin .label,
.md-skin .badge {
    font-family: 'Roboto';
}

.md-skin ul.nav-second-level {
    background-color: inherit;
}

.md-skin .font-bold {
    font-weight: 500;
}

.md-skin .wrapper-content {
    padding: 30px 20px 40px;
}

@media (max-width: 768px) {
    .md-skin .wrapper-content {
        padding: 30px 0 40px;
    }
}

.md-skin .page-heading {
    border-bottom: none !important;
    border-top: 0;
    padding: 0 10px 20px 10px;
    box-shadow: 0 1px 1px -1px rgba(0, 0, 0, 0.34), 0 0 6px 0 rgba(0, 0, 0, 0.14);
}

.md-skin .full-height-layout .page-heading {
    border-bottom: 1px solid #e7eaec !important;
}

.md-skin .ibox {
    clear: both;
    margin-bottom: 25px;
    margin-top: 0;
    padding: 0;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
}

.md-skin .ibox.border-bottom {
    border-bottom: none !important;
}

.md-skin .ibox-title,
.md-skin .ibox-content {
    border-style: none;
}

.md-skin .ibox-title h5 {
    font-size: 16px;
    font-weight: 400;
}

.md-skin a.close-canvas-menu {
    color: #ffffff;
}

.md-skin .welcome-message {
    color: #ffffff;
    font-weight: 300;
}

.md-skin #top-search::-moz-placeholder {
    color: #ffffff;
}

.md-skin #top-search::-webkit-input-placeholder {
    color: #ffffff;
}

.md-skin #nestable-output,
.md-skin #nestable2-output {
    font-family: 'Roboto', lucida grande, lucida sans unicode, helvetica, arial, sans-serif;
}

.md-skin .landing-page {
    font-family: 'Roboto', helvetica, arial, sans-serif;
}

.md-skin .landing-page.navbar-default.navbar-scroll {
    background-color: #fff !important;
}

.md-skin .landing-page.navbar-default {
    background-color: transparent !important;
    box-shadow: none;
}

.md-skin .landing-page.navbar-default .nav li a {
    font-family: 'Roboto', helvetica, arial, sans-serif;
}

.md-skin .nav > li > a {
    color: #676a6c;
    padding: 14px 20px 14px 25px;
}

.md-skin .nav.navbar-right > li > a {
    color: #ffffff;
}

.md-skin .nav > li.active > a {
    color: #5b5d5f;
    font-weight: 700;
}

.md-skin .navbar-default .nav > li > a:hover,
.md-skin .navbar-default .nav > li > a:focus {
    font-weight: 700;
    color: #5b5d5f;
}

.md-skin .nav .open > a,
.md-skin .nav .open > a:hover,
.md-skin .nav .open > a:focus {
    background: #1ab394;
}

.md-skin .navbar-top-links li {
    display: inline-table;
}

.md-skin .navbar-top-links .dropdown-menu li {
    display: block;
}

.md-skin .pace-done .nav-header {
    transition: all 0.4s;
}

.md-skin .nav > li.active {
    background: #f8f8f9;
}

.md-skin .nav-second-level li a {
    padding: 7px 10px 7px 52px;
}

.md-skin .nav-third-level li a {
    padding-left: 62px;
}

.md-skin .navbar-top-links li a {
    padding: 20px 10px;
    min-height: 50px;
}

.md-skin .nav > li > a {
    font-weight: 400;
}

.md-skin .navbar-static-side .nav > li > a:focus,
.md-skin .navbar-static-side .nav > li > a:hover {
    background-color: inherit;
}

.md-skin .navbar-top-links .dropdown-menu li a {
    padding: 3px 20px;
    min-height: inherit;
}

.md-skin .nav-header .navbar-fixed-top a {
    color: #ffffff;
}

.md-skin .nav-header .text-muted {
    color: #ffffff !important;
}

.md-skin .navbar-form-custom .form-control {
    font-weight: 300;
}

.md-skin .mini-navbar .nav-second-level {
    background-color: inherit;
}

.md-skin .mini-navbar li.active .nav-second-level {
    left: 65px;
}

.md-skin .canvas-menu.mini-navbar .nav-second-level {
    background: inherit;
}

.md-skin .pace-done .navbar-static-side,
.md-skin .pace-done .nav-header,
.md-skin .pace-done li.active,
.md-skin .pace-done #page-wrapper,
.md-skin .pace-done .footer {
    -webkit-transition: all 0.4s;
    -moz-transition: all 0.4s;
    -o-transition: all 0.4s;
    transition: all 0.4s;
}

.md-skin .navbar-fixed-top {
    background: #fff;
    transition-duration: 0.4s;
    z-index: 2030;
    border-bottom: none !important;
}

.md-skin .navbar-fixed-top,
.md-skin .navbar-static-top {
    background-color: #1ab394 !important;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
}

.md-skin .navbar-static-side {
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
}

.md-skin #right-sidebar {
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
    border: none;
    z-index: 900;
}

.md-skin .white-bg .navbar-fixed-top,
.md-skin .white-bg .navbar-static-top {
    background: #fff !important;
}

.md-skin .contact-box {
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
    border: none;
}

.md-skin .dashboard-header {
    border-bottom: none !important;
    border-top: 0;
    padding: 20px 20px 20px 20px;
    margin: 30px 20px 0 20px;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
}

@media (max-width: 768px) {
    .md-skin .dashboard-header {
        margin: 20px 0 0 0;
    }
}

.md-skin ul.notes li div {
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
}

.md-skin .file {
    border: none;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
}

.md-skin .mail-box {
    background-color: #ffffff;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
    padding: 0;
    margin-bottom: 20px;
    border: none;
}

.md-skin .mail-box-header {
    border: none;
    background-color: #ffffff;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
    padding: 30px 20px 20px 20px;
}

.md-skin .mailbox-content {
    border: none;
    padding: 20px;
    background: #ffffff;
}

.md-skin .social-feed-box {
    border: none;
    background: #fff;
    margin-bottom: 15px;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
}

.md-skin.landing-page .navbar-default {
    background-color: transparent !important;
    border-color: transparent;
    transition: all 0.3s ease-in-out 0s;
    box-shadow: none;
}

.md-skin.landing-page .navbar-default.navbar-scroll,
.md-skin.landing-page.body-small .navbar-default {
    background-color: #ffffff !important;
}

.md-skin.landing-page .nav > li.active {
    background: inherit;
}

.md-skin.landing-page .navbar-scroll .navbar-nav > li > a {
    padding: 20px 10px;
}

.md-skin.landing-page .navbar-default .nav li a {
    font-family: 'Roboto', helvetica, arial, sans-serif;
}

.md-skin.landing-page .nav > li > a {
    padding: 25px 10px 15px 10px;
}

.md-skin.landing-page .navbar-default .navbar-nav > li > a:hover,
.md-skin.landing-page .navbar-default .navbar-nav > li > a:focus {
    background: inherit;
    color: #1ab394;
}

.md-skin.landing-page.body-small .nav.navbar-right > li > a {
    color: #676a6c;
}

.md-skin .landing_link a,
.md-skin .special_link a {
    color: #ffffff !important;
}

.md-skin.canvas-menu.mini-navbar .nav-second-level {
    background: #f8f8f9;
}

.md-skin.mini-navbar .nav-second-level {
    background-color: #ffffff;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
}

.md-skin.mini-navbar .nav-second-level li a {
    padding-left: 0;
}

.md-skin.mini-navbar.fixed-sidebar .nav-second-level li a {
    padding-left: 52px;
}

.md-skin.top-navigation .nav.navbar-right > li > a {
    padding: 15px 20px;
    color: #676a6c;
}

.md-skin.top-navigation .nav > li a:hover,
.md-skin .top-navigation .nav > li a:focus,
.md-skin.top-navigation .nav .open > a,
.md-skin.top-navigation .nav .open > a:hover,
.md-skin.top-navigation .nav .open > a:focus {
    color: #1ab394;
    background: #ffffff;
}

.md-skin.top-navigation .nav > li.active a {
    color: #1ab394;
    background: #ffffff;
}

.md-skin.fixed-nav #side-menu {
    background-color: #fff;
}

.md-skin.fixed-nav #wrapper.top-navigation #page-wrapper {
    margin-top: 0;
}

.md-skin.fixed-sidebar.mini-navbar .navbar-static-side {
    width: 0;
}

.md-skin.fixed-sidebar.mini-navbar #page-wrapper {
    margin: 0 0 0 0;
}

.md-skin.body-small.fixed-sidebar.mini-navbar #page-wrapper {
    margin: 0 0 0 0;
}

.md-skin.body-small.fixed-sidebar.mini-navbar .navbar-static-side {
    width: 220px;
    background-color: #ffffff;
}

.md-skin.boxed-layout #wrapper {
    background-color: #ffffff;
}

.md-skin.canvas-menu nav.navbar-static-side {
    z-index: 2001;
    background: #ffffff;
    height: 100%;
    position: fixed;
    display: none;
}

@media (min-width: 768px) {
    #page-wrapper {
        position: inherit;
        margin: 0 0 0 240px;
        min-height: 100vh;
    }

    .navbar-static-side {
        z-index: 2001;
        position: absolute;
        width: 220px;
    }

    .navbar-top-links .dropdown-messages,
    .navbar-top-links .dropdown-tasks,
    .navbar-top-links .dropdown-alerts {
        margin-left: auto;
    }
}

@media (max-width: 768px) {
    #page-wrapper {
        position: inherit;
        margin: 0 0 0 0;
        min-height: 100vh;
    }

    .body-small .navbar-static-side {
        display: none;
        z-index: 2001;
        position: absolute;
        width: 70px;
    }

    .body-small.mini-navbar .navbar-static-side {
        display: block;
    }

    .lock-word {
        display: none;
    }

    .navbar-form-custom {
        display: none;
    }

    .navbar-header {
        display: inline;
        float: left;
    }

    .sidebar-panel {
        z-index: 2;
        position: relative;
        width: auto;
        min-height: 100% !important;
    }

    .sidebar-content .wrapper {
        padding-right: 0;
        z-index: 1;
    }

    .fixed-sidebar.body-small .navbar-static-side {
        display: none;
        z-index: 2001;
        position: fixed;
        width: 220px;
    }

    .fixed-sidebar.body-small.mini-navbar .navbar-static-side {
        display: block;
    }

    .ibox-tools {
        float: none;
        text-align: right;
        display: block;
    }

    .navbar-static-side {
        display: none;
    }

    body:not(.mini-navbar) {
        -webkit-transition: background-color 500ms linear;
        -moz-transition: background-color 500ms linear;
        -o-transition: background-color 500ms linear;
        -ms-transition: background-color 500ms linear;
        transition: background-color 500ms linear;
        background-color: #f3f3f4;
    }
}

@media (max-width: 350px) {
    .timeline-item .date {
        text-align: left;
        width: 110px;
        position: relative;
        padding-top: 30px;
    }

    .timeline-item .date i {
        position: absolute;
        top: 0;
        left: 15px;
        padding: 5px;
        width: 30px;
        text-align: center;
        border: 1px solid #e7eaec;
        background: #f8f8f8;
    }

    .timeline-item .content {
        border-left: none;
        border-top: 1px solid #e7eaec;
        padding-top: 10px;
        min-height: 100px;
    }

    .nav.navbar-top-links li.dropdown {
        display: none;
    }

    .ibox-tools {
        float: none;
        text-align: left;
        display: inline-block;
    }
}

.site-input-group-wrapper .site-input-split {
    background-color: #fff;
}

.site-input-group-wrapper .site-input-right {
    border-left-width: 0;
}

.site-input-group-wrapper .site-input-right:hover,
.site-input-group-wrapper .site-input-right:focus {
    border-left-width: 1px;
}

.site-input-group-wrapper .ant-input-rtl.site-input-right {
    border-right-width: 0;
}

.site-input-group-wrapper .ant-input-rtl.site-input-right:hover,
.site-input-group-wrapper .ant-input-rtl.site-input-right:focus {
    border-right-width: 1px;
}
