$color-primary: #123963;
$color-danger: #FC304B;
$color-primary-blur: #01A0C6;
$color-foreign: #FCCE01;

$btn-color-primary: $color-primary-blur;
$btn-color-danger: #ff4d4f;

@mixin screen-small {
    @media (max-width: 375px) {
        @content;
    }
}

@mixin screen-medium {
    @media (min-width: 376px) and (max-width: 767px) {
        @content;
    }
}

@mixin screen-lg {
    @media (min-width: 767px) {
        @content;
    }
}

@mixin screen-max-lg {
    @media (min-width: 1800px) {
        @content;
    }
}
