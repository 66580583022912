@import "../variables";

.ticket {
    &__comments {
        &--avatar {
            border: 1px solid rgba(gray, 0.1);
        }

        &--item {
            .ant-comment-inner {
                padding: 7px 0;
            }

            .ant-comment-content-author-name {
                width: 100%;
            }

            border: 1px solid rgba(gray, 0.3);
            border-radius: 10px;
            padding: 0 10px;
            margin-bottom: 15px;

            &-admin {
                border: 1px solid $color-primary-blur;
            }
        }

        &--content {
            max-height: 500px;
            overflow-y: scroll;
        }
    }
}
