.import-data {
    &__popup-message {
        &--warn {
            .ant-modal-header {
                background-color: #FFC300;
            }
        }

        &--success {
            .ant-modal-header {
                background-color: #4CAF50;
            }
        }

        &--error {
            .ant-modal-header {
                background-color: #B71C1C;
            }
        }
    }
}
