.exclusive-content-layout {
    &--item-parent {
        &:not(:last-child) {
            border-bottom: none;
        }

        .ant-list-header {
            border-bottom: none;
        }

        .ant-spin-nested-loading {
            height: 0;
            overflow: hidden;
            opacity: 0;
        }

        &-open {
            .ant-spin-nested-loading {
                height: 100%;
                overflow: hidden;
                opacity: 1;
            }
        }
    }

    &--form {
        //max-height: 600px;
        //overflow-y: scroll;
    }
}
